import {
  base58Decode,
  base58Encode,
  createAddress,
} from '@keeper-wallet/waves-crypto';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { createContext, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { useAccounts } from '../../accounts/requireAccounts';
import { useWavesNfts } from '../../cache/hooks';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { PortfolioNftCollection } from '../../portfolio/nftCollection';
import { PortfolioNftCollections } from '../../portfolio/nftCollections';
import { useAppSelector } from '../../store/react';
import { type AssetDetails } from '../../waves/redux';
import * as styles from './portfolio.module.css';

interface NftsPageContextValue {
  nfts?: AssetDetails[];
}

const NftsPageContext = createContext<NftsPageContextValue>({});

export function NftsPage() {
  const network = useAppSelector(state => state.network);

  const { chainId } = WAVES_NETWORK_CONFIGS[network];

  const selectedAccounts = useAccounts({ onlySelected: true });

  const addresses = useMemo(
    () =>
      selectedAccounts?.map(account =>
        base58Encode(createAddress(base58Decode(account.publicKey), chainId))
      ),
    [selectedAccounts, chainId]
  );

  const wavesNfts = useWavesNfts({
    addresses,
    limit: 1000,
    network,
  });

  const nfts = useMemo(() => {
    if (!addresses) return;

    const result: AssetDetails[] = [];
    for (const address of addresses) {
      const nftsOnAddress = wavesNfts[address];
      if (!nftsOnAddress) return;

      result.push(...nftsOnAddress);
    }

    return result;
  }, [addresses, wavesNfts]);

  const value = useMemo(
    (): NftsPageContextValue => ({
      nfts,
    }),
    [nfts]
  );

  return (
    <NftsPageContext.Provider value={value}>
      <Outlet />
    </NftsPageContext.Provider>
  );
}

export function PortfolioNftCollectionsPage() {
  const { i18n } = useLingui();
  const { nfts } = useContext(NftsPageContext);

  if (nfts?.length === 0) {
    return <h2 className={styles.emptyMessage}>{t(i18n)`No NFTs yet`}</h2>;
  }

  return (
    <PortfolioNftCollections
      collections={
        nfts &&
        Object.values(
          nfts.reduce((acc, nft) => {
            acc[nft.issuer] ??= {
              id: nft.issuer,
              itemCount: 0,
              label: nft.issuer,
            };

            acc[nft.issuer].itemCount++;

            return acc;
          }, {} as Record<string, { id: string; itemCount: number; label: string; src?: string }>)
        )
      }
    />
  );
}

export function PortfolioNftCollectionPage() {
  const { i18n } = useLingui();
  const { nfts } = useContext(NftsPageContext);
  const { issuerId } = useParams();
  invariant(issuerId);

  const filteredNfts = nfts
    ?.filter(({ issuer }) => issuer === issuerId)
    .map(nft => ({
      id: nft.assetId,
      label: nft.name,
    }));

  if (filteredNfts?.length === 0) {
    return <h2 className={styles.emptyMessage}>{t(i18n)`No NFTs yet`}</h2>;
  }

  return (
    <PortfolioNftCollection collectionName={issuerId} nfts={filteredNfts} />
  );
}
