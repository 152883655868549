import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { formatUsdPrice } from '../_core/formatUsdPrice';
import { isNotNull } from '../_core/predicates';
import { useMatchesMedia } from '../_core/useMatchesMedia';
import type {
  DataServiceAssets,
  DataServiceProtocol,
  DataServiceUsdPrices,
} from '../dataService/redux';
import { TabPanelHeader } from '../portfolio/tabPanelHeader';
import { InvestmentListDesktop } from './investmentListDesktop';
import { InvestmentListMobile } from './investmentListMobile';
import * as styles from './investments.module.css';
import { InvestmentsOverview } from './investmentsOverview';
import { type Investment } from './utils';

interface Props {
  dataServiceAssets: DataServiceAssets;
  dataServiceProtocols: DataServiceProtocol[] | undefined;
  investments: Investment[] | undefined;
  usdPrices: DataServiceUsdPrices;
  totalWorth: BigNumber | undefined;
}

export function PortfolioInvestments({
  dataServiceAssets,
  dataServiceProtocols,
  investments,
  usdPrices,
  totalWorth,
}: Props) {
  const { i18n } = useLingui();

  const isMobile = useMatchesMedia('(max-width: 768px)');

  const productsByProtocol = investments?.reduce((acc, product) => {
    acc[product.protocol_id] ??= [];
    acc[product.protocol_id].push(product);

    return acc;
  }, {} as Record<string, Investment[]>);

  const protocolsInvestments = dataServiceProtocols
    ?.map(protocol => {
      const protocolProducts = productsByProtocol?.[protocol.id];

      return (
        protocolProducts && {
          protocol,
          protocolProducts,
        }
      );
    })
    .filter(isNotNull)
    .map(({ protocol, protocolProducts }) => ({
      id: protocol.id,
      name: protocol.name,
      logo: protocol.icon_url,
      link: `#${protocol.id}`,
      value: protocolProducts.reduce((protocolProductsSum, { amounts }) => {
        const amountsSum = amounts.reduce(
          (productAmountsSum, amount) =>
            productAmountsSum.add(
              amount.getTokens().mul(usdPrices[amount.assetInfo.assetId] ?? '0')
            ),
          new BigNumber(0)
        );

        return protocolProductsSum.add(amountsSum);
      }, new BigNumber(0)),
    }));

  const isLoading =
    dataServiceProtocols == null ||
    investments == null ||
    protocolsInvestments == null;

  const location = useLocation();

  useEffect(() => {
    const scrollToId = location.hash.replace('#', '');

    if (isLoading || !scrollToId) {
      return;
    }

    const element = document.getElementById(scrollToId);

    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top });
    }
  }, [isLoading, isMobile, location.pathname, location.key, location.hash]);

  return (
    <div>
      <TabPanelHeader
        caption={t(i18n)`Investments worth`}
        heading={
          totalWorth == null || isLoading ? null : formatUsdPrice(totalWorth)
        }
      />

      {!isLoading && protocolsInvestments.length === 0 ? null : (
        <InvestmentsOverview
          investments={isLoading ? undefined : protocolsInvestments}
        />
      )}

      {isMobile == null ? null : isMobile ? (
        <InvestmentListMobile
          className={styles.investmentListMobile}
          dataServiceAssets={dataServiceAssets}
          investments={isLoading ? undefined : investments}
          protocols={isLoading ? undefined : dataServiceProtocols}
          usdPrices={usdPrices}
        />
      ) : (
        <InvestmentListDesktop
          className={styles.investmentListDesktop}
          dataServiceAssets={dataServiceAssets}
          investments={isLoading ? undefined : investments}
          protocols={isLoading ? undefined : dataServiceProtocols}
          usdPrices={usdPrices}
        />
      )}
    </div>
  );
}
