import { type Placement } from '@popperjs/core';
import React, {
  type ReactElement,
  type ReactNode,
  type RefCallback,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import * as Popper from 'react-popper';

import * as styles from './tooltip.module.css';

interface RenderProps {
  ref: RefCallback<HTMLElement>;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onTouchStart: () => void;
}

interface Props {
  children: (renderProps: RenderProps) => ReactElement;
  content: ReactNode;
  isOpen?: boolean;
  disabled?: boolean;
  placement?: Placement;
}

export function Tooltip({
  children,
  content,
  isOpen,
  disabled,
  placement,
}: Props) {
  const [isActive, setIsActive] = useState(false);
  const [targetEl, setTargetEl] = useState<HTMLElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);

  const popper = Popper.usePopper(targetEl, popperEl, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          padding: 5,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (!isActive || !targetEl) {
      return;
    }

    const handleTouchStart = (event: TouchEvent) => {
      if (!(event.target instanceof Node) || !targetEl.contains(event.target)) {
        setIsActive(false);
      }
    };

    window.addEventListener('touchstart', handleTouchStart, false);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart, false);
    };
  }, [isActive, targetEl]);

  return (
    <>
      {children({
        ref: node => {
          setTargetEl(node);
        },
        onMouseEnter: () => {
          setIsActive(true);
        },
        onMouseLeave: () => {
          setIsActive(false);
        },
        onTouchStart: () => {
          setIsActive(prevState => !prevState);
        },
      })}

      {__ENTRY_TARGET__ === 'webPage' &&
        (isOpen || (isActive && !disabled)) &&
        createPortal(
          <div
            className={styles.tooltipRoot}
            ref={node => {
              setPopperEl(node);
            }}
            style={popper.styles.popper}
            {...popper.attributes.popper}
          >
            <div className={styles.tooltipContent}>{content}</div>

            <div
              className={styles.tooltipArrow}
              data-popper-arrow=""
              style={popper.styles.arrow}
            >
              <div className={styles.tooltipArrowInner}>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="currentColor"
                >
                  <path d="M6.2 6.93333C6.6 7.46667 7.4 7.46667 7.8 6.93333L13.3 -0.4C13.7944 -1.05924 13.324 -2 12.5 -2H1.5C0.675955 -2 0.205573 -1.05924 0.7 -0.4L6.2 6.93333Z" />
                </svg>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
