import {
  base58Decode,
  base58Encode,
  createAddress,
} from '@keeper-wallet/waves-crypto';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type SignerTransferTx } from '@waves/signer';
import { useEffect, useMemo, useState } from 'react';
import {
  array,
  boolean,
  enums,
  type Infer,
  literal,
  nullable,
  number,
  string,
  type,
  union,
} from 'superstruct';
import invariant from 'tiny-invariant';

import {
  isErrorLike,
  isKeeperInvalidNetworkError,
  isKeeperNoAccountsError,
  isKeeperNotInstalledError,
  isUserRejectionError,
} from '../_core/errors';
import { handleResponse } from '../_core/handleResponse';
import { Spinner } from '../_core/spinner';
import { useAccounts } from '../accounts/requireAccounts';
import { WalletType } from '../accounts/types';
import { createWalletProvider } from '../accounts/utils';
import { track } from '../analytics';
import {
  useDataServiceAssets,
  useDataServiceUsdPrices,
  useWavesAssetsDetails,
  useWavesBalances,
} from '../cache/hooks';
import { Container } from '../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { Network } from '../network/types';
import { SendAssets, useSendAssets } from '../send/sendAssets';
import {
  SendStatusDialog,
  type SendStatusDialogProps,
} from '../send/sendStatusDialog';
import { useAppSelector } from '../store/react';
import { type AssetDetails } from '../waves/redux';
import * as styles from './send.module.css';

const AssetDetails = type({
  assetId: string(),
  name: string(),
  decimals: number(),
  description: string(),
  issueHeight: number(),
  issueTimestamp: number(),
  issuer: string(),
  quantity: string(),
  reissuable: boolean(),
  scripted: boolean(),
  minSponsoredAssetFee: nullable(string()),
  originTransactionId: string(),
});

const TransactionStatus = union([
  type({ status: literal('not_found'), id: string() }),
  type({ status: literal('unconfirmed'), id: string() }),
  type({
    status: literal('confirmed'),
    id: string(),
    height: number(),
    confirmations: number(),
    applicationStatus: enums(['succeeded', 'failed']),
    spentComplexity: number(),
  }),
]);

type TransactionStatus = Infer<typeof TransactionStatus>;

async function fetchTransactionStatuses(
  network: Network,
  txIds: string[],
  { signal }: { signal?: AbortSignal } = {}
) {
  const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  const url = new URL(`/transactions/status`, nodeUrl);

  txIds.forEach(txId => {
    url.searchParams.append('id', txId);
  });

  return fetch(url, {
    signal,
    headers: {
      Accept: 'application/json; large-significand-format=string',
    },
  }).then(handleResponse(array(TransactionStatus)));
}

export function SendAssetsPage() {
  const network = useAppSelector(state => state.network);
  const { chainId } = WAVES_NETWORK_CONFIGS[network];

  const accounts = useAccounts();
  const { publicKey: selectedAccountPublicKey } = accounts[0];

  const { values, setValues, resetValues } = useSendAssets({
    senderPublicKey: selectedAccountPublicKey ?? '',
  });
  const addresses = useMemo(
    () =>
      accounts?.map(({ publicKey }) =>
        base58Encode(createAddress(base58Decode(publicKey), chainId))
      ) ?? [],
    [chainId, accounts]
  );

  const { i18n } = useLingui();

  const isLoading = accounts == null;
  const [formError, setFormError] = useState('');

  const [transferData, setTransferData] = useState<
    SendStatusDialogProps['transferData'] | null
  >(null);
  const [transferState, setTransferState] = useState<
    SendStatusDialogProps['transferState'] | null
  >(null);

  const [showStatusDialog, setShowStatusDialog] = useState(false);

  const wavesBalances = useWavesBalances({ addresses, network });

  const assetIds = useMemo((): string[] => {
    if (!addresses) return [];

    const assetIdsSet = new Set<string>();

    for (const address of addresses) {
      const accountBalances = wavesBalances[address];

      if (accountBalances) {
        for (const balance of accountBalances.list) {
          if (balance) {
            assetIdsSet.add(balance.assetId);
          }
        }
      }
    }

    return Array.from(assetIdsSet);
  }, [addresses, wavesBalances]);

  const wavesAssetsDetails = useWavesAssetsDetails({ assetIds, network });
  const usdPrices = useDataServiceUsdPrices({ assetIds, network });

  const dataServiceAssets = useDataServiceAssets({
    network,
  });

  useEffect(() => {
    if (transferData?.txId == null) {
      return;
    }

    const abortController = new AbortController();

    const statusRetryMs = 5000;
    let statusRetryTimeout: ReturnType<typeof setTimeout> | null = null;
    let statusAttempts = 0;

    async function fetchStatus(prevStatus: TransactionStatus | null) {
      statusAttempts++;

      invariant(transferData?.txId);

      const [status] = await fetchTransactionStatuses(
        network,
        [transferData.txId],
        {
          signal: abortController.signal,
        }
      );

      if (
        status.status === 'not_found' &&
        prevStatus &&
        prevStatus.status === 'unconfirmed'
      ) {
        setTransferState({ type: 'transactionLost' });
        return;
      }

      if (status.status === 'unconfirmed' || status.status === 'not_found') {
        if (statusAttempts > 5) {
          setTransferState({ type: 'transactionLost' });
        } else {
          statusRetryTimeout = setTimeout(
            () => fetchStatus(status),
            statusRetryMs
          );
        }
        return;
      }

      if (status.applicationStatus === 'failed') {
        setTransferState({ type: 'applicationFailed' });
        return;
      }

      setTransferState({ type: 'applicationSuccess' });
    }

    fetchStatus(null);

    return () => {
      abortController.abort();

      if (statusRetryTimeout != null) {
        clearTimeout(statusRetryTimeout);
        statusRetryTimeout = null;
      }
    };
  }, [network, transferData]);

  return (
    <Container>
      <h1 className={styles.sendPageTitle}>
        <Trans>Send Assets</Trans>
      </h1>

      {isLoading ? (
        <p className={styles.sendSpinnerWrapper}>
          <Spinner size={32} />
        </p>
      ) : (
        <>
          <SendAssets
            values={values}
            setValues={setValues}
            accounts={accounts}
            balancesByAddresses={Object.fromEntries(
              Object.entries(wavesBalances).map(([address, balance]) => [
                address,
                balance?.list,
              ])
            )}
            assets={wavesAssetsDetails}
            assetsInfo={dataServiceAssets}
            usdPrices={usdPrices}
            onSubmit={({ sender, amount, recipient, fee }) => {
              setFormError('');

              async function signTransaction(
                tx: SignerTransferTx,
                walletType: WalletType
              ) {
                try {
                  const [{ Signer }, provider] = await Promise.all([
                    import(
                      /* webpackChunkName: "signer" */
                      '@waves/signer'
                    ),
                    createWalletProvider(origin, walletType),
                  ]);

                  invariant(provider, 'provider');

                  const signer = new Signer({
                    NODE_URL: WAVES_NETWORK_CONFIGS[network].nodeUrl,
                  });
                  signer.setProvider(provider);

                  const { publicKey } = await signer.login();
                  if (publicKey !== tx.senderPublicKey) {
                    await signer.logout();

                    setFormError(
                      t(i18n)`Please switch your account in ${
                        walletType === WalletType.KeeperExtension
                          ? 'Keeper Wallet Extension'
                          : 'Keeper Wallet Mobile'
                      } and try again`
                    );
                    return;
                  }
                  const signedTxs = await signer.transfer(tx).sign();

                  setShowStatusDialog(true);
                  setTransferState({ type: 'pending' });
                  setTransferData({
                    sender,
                    amount,
                    recipient,
                    fee,
                  });

                  const txs = await signer.broadcast(signedTxs);

                  invariant(txs.length === 1);

                  const { id: txId } = txs[0];

                  track({
                    eventType: 'broadcast transaction',
                    type: 'send assets',
                    transactionId: txId,
                    walletType,
                  });

                  setTransferData(
                    prevState => prevState && { ...prevState, txId }
                  );

                  resetValues();
                } catch (err) {
                  if (isUserRejectionError(err)) return;

                  if (isKeeperInvalidNetworkError(err)) {
                    setFormError(
                      t(i18n)`Please, switch network to ${
                        network === Network.Mainnet ? 'Mainnet' : 'Testnet'
                      } in Keeper Wallet Extension and try again`
                    );
                    return;
                  } else if (isKeeperNotInstalledError(err)) {
                    setFormError(
                      t(i18n)`Keeper Wallet Extension is not installed`
                    );
                    return;
                  } else if (isKeeperNoAccountsError(err)) {
                    setFormError(
                      t(
                        i18n
                      )`You don't have any accounts in Keeper Wallet Extension`
                    );
                    return;
                  } else {
                    // eslint-disable-next-line no-console
                    console.error(err);

                    setTransferState({
                      type: 'broadcastError',
                      message: isErrorLike(err)
                        ? err.message
                        : t(i18n)`Unexpected error occurred`,
                    });
                    return;
                  }
                }
              }

              signTransaction(
                {
                  type: 4,
                  senderPublicKey: sender.publicKey,
                  amount: amount.getCoins().toString(),
                  assetId: getAssetId(amount.assetInfo),
                  recipient,
                  fee: fee.getCoins().toString(),
                  feeAssetId: getAssetId(fee.assetInfo),
                },
                sender.walletType
              );
            }}
            formError={formError}
          />
          {transferData && transferState && (
            <SendStatusDialog
              assetsInfo={dataServiceAssets}
              usdPrices={usdPrices}
              isOpen={showStatusDialog}
              onIsOpenChange={open => {
                if (!open) {
                  setTransferData(null);
                  setTransferState(null);
                }
              }}
              transferData={transferData}
              transferState={transferState}
            />
          )}
        </>
      )}
    </Container>
  );
}

function getAssetId(assetInfo: AssetDetails) {
  return assetInfo.assetId === 'WAVES' ? null : assetInfo.assetId;
}
