import {
  base58Decode,
  base58Encode,
  createAddress,
} from '@keeper-wallet/waves-crypto';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import { useMemo } from 'react';

import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Money } from '../_core/money';
import { isNotNull } from '../_core/predicates';
import { useAccounts } from '../accounts/requireAccounts';
import {
  useDataServiceAssets,
  useDataServiceLeasesInfo,
  useDataServiceProducts,
  useDataServiceProtocols,
  useDataServiceUsdPrices,
  useWavesActiveLeases,
  useWavesAssetsDetails,
  useWavesBalances,
  useWavesNfts,
} from '../cache/hooks';
import { RecentAssets } from '../dashboard/recentAssets';
import {
  TopInvestments,
  type TopInvestmentsItem,
} from '../dashboard/topInvestments';
import { TopNfts } from '../dashboard/topNfts';
import type { DataServiceProtocol } from '../dataService/redux';
import {
  InvestmentsOverview,
  type InvestmentsOverviewItem,
} from '../investments/investmentsOverview';
import { PageHeader } from '../investments/pageHeader';
import {
  type Investment,
  mapDataServiceProductToInvestment,
  mapWavesLeasingToInvestment,
  mergeWavesLeasesByRecipient,
} from '../investments/utils';
import { Container } from '../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import { type AssetDetails, WAVES_ASSET_DETAILS } from '../waves/redux';
import * as styles from './dashboard.module.css';

const TOP_NFT_COUNT = 4;

export function DashboardPage() {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);

  const dataServiceAssets = useDataServiceAssets({
    network,
  });

  const { chainId } = WAVES_NETWORK_CONFIGS[network];

  const selectedAccounts = useAccounts({ onlySelected: true });

  const addresses = useMemo(
    () =>
      selectedAccounts?.map(account =>
        base58Encode(createAddress(base58Decode(account.publicKey), chainId))
      ),
    [selectedAccounts, chainId]
  );

  const wavesBalances = useWavesBalances({ addresses, network });
  const dataServiceProducts = useDataServiceProducts({ addresses, network });
  const activeLeases = useWavesActiveLeases({
    network,
    addresses,
    only: 'outgoing',
  });
  const leasesInfo = useDataServiceLeasesInfo();
  const areLeasesLoading = addresses.some(
    address => activeLeases[address] == null
  );

  const assetIds = useMemo((): string[] => {
    if (!addresses) return [];

    const assetIdsSet = new Set<string>();

    for (const address of addresses) {
      const accountBalances = wavesBalances[address];
      const accountProducts = dataServiceProducts[address];

      if (accountBalances) {
        for (const balance of accountBalances.list) {
          if (balance) {
            assetIdsSet.add(balance.assetId);
          }
        }
      }

      if (accountProducts) {
        for (const product of accountProducts) {
          for (const amount of product.amounts) {
            assetIdsSet.add(amount.asset_id);
          }
        }
      }
    }

    return Array.from(assetIdsSet);
  }, [addresses, dataServiceProducts, wavesBalances]);

  const wavesAssetsDetails = useWavesAssetsDetails({ assetIds, network });
  const usdPrices = useDataServiceUsdPrices({ assetIds, network });

  const balances = useMemo(() => {
    if (!addresses) return;

    const mergedBalancesByAssetIds: Record<string, Money> = {};

    for (const address of addresses) {
      const accountBalance = wavesBalances[address];
      if (!accountBalance) return;

      for (const { assetId, balance } of accountBalance.list) {
        const asset = wavesAssetsDetails[assetId];
        if (!asset) return;

        const balanceMoney = Money.fromCoins(balance, asset);

        if (!mergedBalancesByAssetIds[assetId]) {
          mergedBalancesByAssetIds[assetId] = balanceMoney;
        } else {
          mergedBalancesByAssetIds[assetId] = Money.fromCoins(
            mergedBalancesByAssetIds[assetId]
              .getCoins()
              .add(balanceMoney.getCoins()),
            asset
          );
        }
      }
    }

    return Object.values(mergedBalancesByAssetIds);
  }, [addresses, wavesAssetsDetails, wavesBalances]);

  const walletWorth = balances?.reduce(
    (acc, balance) =>
      acc.add(
        balance.getTokens().mul(usdPrices[balance.assetInfo.assetId] ?? '0')
      ),
    new BigNumber(0)
  );

  const dataServiceProtocols = useDataServiceProtocols({ network });

  const investmentsByAddress = useMemo(() => {
    const investments: Record<string, Investment[]> = {};

    for (const address of addresses) {
      const products = dataServiceProducts[address];
      const leases = activeLeases[address] ?? [];

      if (!products) {
        return;
      }

      investments[address] = [
        ...(products ?? []).map(product =>
          mapDataServiceProductToInvestment({
            ...product,
            amounts: product.amounts
              .map(amount => {
                const asset = wavesAssetsDetails[amount.asset_id];
                if (!asset) return;

                return Money.fromCoins(amount.coins, asset);
              })
              .filter(isNotNull),
          })
        ),
        ...mergeWavesLeasesByRecipient(leases ?? []).map(leasing =>
          mapWavesLeasingToInvestment(leasing, leasesInfo)
        ),
      ];
    }

    return investments;
  }, [
    activeLeases,
    addresses,
    dataServiceProducts,
    leasesInfo,
    wavesAssetsDetails,
  ]);

  const topProducts = useMemo(() => {
    if (!addresses || !dataServiceProtocols || !investmentsByAddress) return;

    const itemMap = new Map<string, TopInvestmentsItem>();

    for (const address of addresses) {
      const investments = investmentsByAddress[address];
      if (!investments) return;

      for (const investment of investments) {
        const protocol =
          dataServiceProtocols.byProtocolId[investment.protocol_id];
        if (!protocol) return;

        let totalWorth = new BigNumber(0);

        for (const amount of investment.amounts) {
          totalWorth = totalWorth.add(
            amount.getTokens().mul(usdPrices[amount.assetInfo.assetId] ?? '0')
          );
        }

        const existingItem = itemMap.get(investment.product_id);

        if (existingItem) {
          existingItem.totalWorth = existingItem.totalWorth.add(totalWorth);
        } else {
          itemMap.set(investment.product_id, {
            productName: investment.name,
            productLogo: investment.icon_url,
            productId: investment.product_id,
            protocolId: protocol.id,
            protocolName: protocol.name,
            protocolLogo: protocol.icon_url,
            totalWorth,
          });
        }
      }
    }

    return Array.from(itemMap.values()).sort((a, b) => {
      if (a.totalWorth.gt(b.totalWorth)) {
        return -1;
      }

      if (a.totalWorth.lt(b.totalWorth)) {
        return 1;
      }

      return a.productName.localeCompare(b.productName);
    });
  }, [addresses, dataServiceProtocols, investmentsByAddress, usdPrices]);

  const protocolsInvestments = useMemo<
    InvestmentsOverviewItem[] | undefined
  >(() => {
    if (!addresses || !dataServiceProtocols || !investmentsByAddress) return;

    const worthByProtocol = new Map<DataServiceProtocol, BigNumber>();

    for (const address of addresses) {
      const investments = investmentsByAddress[address];
      if (!investments) return;

      for (const product of investments) {
        const protocol = dataServiceProtocols.byProtocolId[product.protocol_id];
        if (!protocol) return;

        let worth = worthByProtocol.get(protocol) ?? new BigNumber(0);

        for (const amount of product.amounts) {
          const assetDetails = wavesAssetsDetails[amount.assetInfo.assetId];
          if (!assetDetails) return;

          worth = worth.add(
            amount.getTokens().mul(usdPrices[amount.assetInfo.assetId] ?? '0')
          );
        }

        worthByProtocol.set(protocol, worth);
      }
    }

    return Array.from(worthByProtocol.entries(), ([protocol, worth]) => ({
      id: protocol.id,
      name: protocol.name,
      logo: protocol.icon_url,
      value: worth,
      link: `/portfolio/investments#${protocol.id}`,
    }));
  }, [
    addresses,
    dataServiceProtocols,
    investmentsByAddress,
    usdPrices,
    wavesAssetsDetails,
  ]);

  const netWorth =
    protocolsInvestments == null || walletWorth == null || areLeasesLoading
      ? undefined
      : protocolsInvestments
          .reduce((acc, { value }) => acc.add(value), new BigNumber(0))
          .add(walletWorth);

  const wavesNfts = useWavesNfts({ addresses, limit: TOP_NFT_COUNT, network });

  const topNfts = useMemo(() => {
    if (!addresses) return;

    const nftArrays: AssetDetails[][] = [];
    for (const address of addresses) {
      const nfts = wavesNfts[address];
      if (!nfts) return;

      nftArrays.push(nfts);
    }

    const maxLen = Math.max(...nftArrays.map(nftArray => nftArray.length));

    const result: AssetDetails[] = [];

    outer: for (let i = 0; i < maxLen; i++) {
      for (const nfts of nftArrays) {
        const nft = nfts.at(i);

        if (nft) {
          result.push(nft);

          if (result.length === TOP_NFT_COUNT) break outer;
        }
      }
    }

    return result;
  }, [addresses, wavesNfts]);

  const isLoading =
    balances == null ||
    protocolsInvestments == null ||
    topNfts == null ||
    topProducts == null ||
    walletWorth == null;

  return (
    <>
      <PageHeader
        caption={t(i18n)`Net worth`}
        heading={
          netWorth == null || isLoading ? undefined : formatUsdPrice(netWorth)
        }
        className={styles.header}
      />

      <Container className={styles.content}>
        {(isLoading || protocolsInvestments.length > 0) && (
          <section className={styles.investmentsOverview}>
            <h2 className={styles.investmentsOverviewHeading}>
              {t(i18n)`Protocols overview`}
            </h2>

            <InvestmentsOverview
              investments={
                isLoading
                  ? undefined
                  : [
                      {
                        id: 'wallet',
                        name: t(i18n)`Wallet`,
                        value: walletWorth,
                        logo: new URL(
                          '../investments/images/wallet.svg',
                          import.meta.url
                        ).pathname,
                        pinned: true,
                        link: `/portfolio`,
                      },
                      ...protocolsInvestments,
                    ]
              }
            />
          </section>
        )}

        <div className={styles.widgetsContainer}>
          <RecentAssets
            className={styles.column1}
            items={
              isLoading
                ? undefined
                : balances
                    .map(balance => {
                      const hasPrice = new BigNumber(
                        usdPrices[balance.assetInfo.assetId] ?? '0'
                      ).gt(0);

                      const asset =
                        dataServiceAssets[balance.assetInfo.assetId];

                      return {
                        id: balance.assetInfo.assetId,
                        ticker: asset?.ticker || balance.assetInfo.name,
                        logo: asset?.url,
                        price: hasPrice
                          ? new BigNumber(
                              usdPrices[balance.assetInfo.assetId] ?? '0'
                            )
                          : undefined,
                        priceChange: undefined,
                        available: balance.getTokens(),
                        worth: hasPrice
                          ? balance
                              .getTokens()
                              .mul(usdPrices[balance.assetInfo.assetId] ?? '0')
                          : undefined,
                      };
                    })
                    .sort((a, b) => {
                      const aWorth = new BigNumber(a.worth ?? 0);
                      const bWorth = new BigNumber(b.worth ?? 0);

                      if (a.id === WAVES_ASSET_DETAILS.assetId) {
                        return -1;
                      }

                      if (aWorth.gt(bWorth)) {
                        return -1;
                      }

                      if (aWorth.lt(bWorth)) {
                        return 1;
                      }

                      return a.ticker.localeCompare(b.ticker);
                    })
            }
          />

          <TopInvestments
            className={styles.column2}
            items={isLoading ? undefined : topProducts}
          />

          {(isLoading || topNfts.length > 0) && (
            <TopNfts
              className={styles.column1}
              items={
                isLoading
                  ? undefined
                  : topNfts.map(nft => ({
                      id: nft.assetId,
                      issuerLabel: nft.issuer,
                      label: nft.name,
                    }))
              }
            />
          )}
        </div>
      </Container>
    </>
  );
}
