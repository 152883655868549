import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useEffect } from 'react';
import {
  type IndexRouteObject,
  type NonIndexRouteObject,
  Outlet,
  redirect,
} from 'react-router-dom';
import invariant from 'tiny-invariant';

import { type MetaDescriptor, type MetaFunctionArgs } from './_core/meta';
import {
  deleteAccount,
  editAccount,
  setSelectedAccount,
} from './accounts/redux';
import { RequireAccounts } from './accounts/requireAccounts';
import { type PrivateAccountData, WalletType } from './accounts/types';
import { track } from './analytics';
import { Document } from './document';
import { type SentryConfig } from './entry';
import { Layout } from './layout/layout';
import { setNetwork } from './network/redux';
import { isValidNetworkValue } from './network/utils';
import { demoLoader, Demos } from './routes/_demos';
import { createErrorMeta, ErrorPage } from './routes/_error';
import { AddAccountPage, createAddAccountMeta } from './routes/addAccount';
import { DashboardPage } from './routes/dashboard';
import { IframePage } from './routes/iframe';
import { createNftLoader, NftPage } from './routes/nft';
import { InvestmentsPage } from './routes/portfolio/investments';
import {
  NftsPage,
  PortfolioNftCollectionPage,
  PortfolioNftCollectionsPage,
} from './routes/portfolio/nfts';
import { PortfolioPage } from './routes/portfolio/portfolio';
import { WalletPage } from './routes/portfolio/wallet';
import {
  requestWalletAccessMeta,
  RequestWalletAccessPage,
} from './routes/requestWalletAccess';
import { SendAssetsPage } from './routes/send';
import { SwapAssetsPage } from './routes/swap';
import { type AppStore } from './store/types';

interface AppRouteParams {
  meta?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((args: MetaFunctionArgs<any>) => MetaDescriptor | void) | MetaDescriptor;
}

type AppIndexRouteInput = IndexRouteObject & AppRouteParams;

type AppNonIndexRouteInput = Omit<NonIndexRouteObject, 'children' | 'id'> & {
  children?: AppRouteInput[];
} & AppRouteParams;

type AppRouteInput = AppIndexRouteInput | AppNonIndexRouteInput;

export type AppRouteObject = AppRouteInput & {
  id: string;
};

function convertRoutes(
  routes: AppRouteInput[],
  parentPath: number[] = []
): AppRouteObject[] {
  return routes.map((route, index) => {
    const treePath = [...parentPath, index];
    const id = treePath.join('-');

    return route.index
      ? { ...route, id }
      : {
          ...route,
          id,
          children: route.children
            ? convertRoutes(route.children, treePath)
            : undefined,
        };
  });
}

export function createRoutes({
  i18n,
  store,
  sentryConfig,
}: {
  i18n: I18n;
  store: AppStore;
  sentryConfig: SentryConfig | undefined;
}) {
  const title = 'Keeper Wallet';

  const description = t(
    i18n
  )`Your entry point to the Waves blockchain and Waves-powered web services`;

  return convertRoutes([
    {
      meta: {
        'application-name': 'Keeper Wallet',
        title,
        description,
        'og:title': title,
        'og:description': description,
      },
      element: (
        <Document>
          <Outlet />
        </Document>
      ),
      errorElement: (
        <Document>
          <ErrorPage />
        </Document>
      ),
      children: [
        ...(__DEV__
          ? [
              {
                path: '_demos/*',
                element: <Demos />,
                loader: demoLoader,
              },
            ]
          : []),
        {
          path: '/',
          meta: createErrorMeta({ i18n }),
          element: (
            <Layout>
              <Outlet />
            </Layout>
          ),
          errorElement: (
            <Layout>
              <ErrorPage />
            </Layout>
          ),
          children: [
            {
              index: true,
              element: (
                <RequireAccounts>
                  <DashboardPage />
                </RequireAccounts>
              ),
            },
            ...(sentryConfig?.environment !== 'production'
              ? [
                  {
                    path: '/debug-sentry',
                    children: [
                      {
                        index: true,
                        Component: () => {
                          return (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 12,
                                }}
                              >
                                <h1>Emit error on:</h1>
                                <p>
                                  <button
                                    onClick={() => {
                                      throw new Error(
                                        'Error from component event handler'
                                      );
                                    }}
                                  >
                                    Event handler
                                  </button>
                                </p>
                                <p>
                                  <a href="/debug-sentry/componentEffect">
                                    Component effect
                                  </a>
                                </p>
                                <p>
                                  <a href="/debug-sentry/reactRouterLoader">
                                    React-router loader
                                  </a>
                                </p>
                                <p>
                                  <a href="/debug-sentry/expressRouterHandler">
                                    Express router handler
                                  </a>
                                </p>
                              </div>
                            </>
                          );
                        },
                      },
                      {
                        path: 'componentEffect',
                        Component: () => {
                          useEffect(() => {
                            throw new Error('Error from component useEffect');
                          }, []);

                          return <h1>Emits error</h1>;
                        },
                      },
                      {
                        path: 'reactRouterLoader',
                        loader: () => {
                          throw new Error('Error from react-router loader');
                        },
                      },
                    ],
                  },
                ]
              : []),
            {
              path: 'portfolio',
              element: (
                <RequireAccounts>
                  <PortfolioPage />
                </RequireAccounts>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <RequireAccounts>
                      <WalletPage />
                    </RequireAccounts>
                  ),
                },
                {
                  path: 'investments',
                  element: (
                    <RequireAccounts>
                      <InvestmentsPage />
                    </RequireAccounts>
                  ),
                },
                {
                  path: 'nfts',
                  element: (
                    <RequireAccounts>
                      <NftsPage />
                    </RequireAccounts>
                  ),
                  children: [
                    {
                      index: true,
                      element: <PortfolioNftCollectionsPage />,
                    },
                    {
                      path: ':issuerId',
                      element: <PortfolioNftCollectionPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'iframe',
              element: <IframePage />,
            },
            {
              path: 'nfts/:id',
              loader: createNftLoader({ i18n, store }),
              element: <NftPage />,
            },
            {
              path: 'request-wallet-access',
              meta: requestWalletAccessMeta,
              element: <RequestWalletAccessPage />,
            },
            {
              path: 'add-account',
              meta: createAddAccountMeta({ i18n }),
              element: <AddAccountPage />,
            },
            {
              path: 'swap',
              element: (
                <RequireAccounts>
                  <SwapAssetsPage />
                </RequireAccounts>
              ),
            },
            {
              path: 'send',
              element: (
                <RequireAccounts>
                  <SendAssetsPage />
                </RequireAccounts>
              ),
            },
            {
              path: '*',
              loader: () => {
                throw new Response(t(i18n)`Page Not Found`, { status: 404 });
              },
              element: null,
            },
            {
              path: '_actions',
              loader: () => {
                throw new Response(t(i18n)`Page Not Found`, { status: 404 });
              },
              element: null,
              children: [
                {
                  path: 'change-network',
                  action: async ({ request }) => {
                    const formData = await request.formData();
                    const network = formData.get('network');
                    invariant(isValidNetworkValue(network));

                    store.dispatch(setNetwork(network));

                    return null;
                  },
                  element: null,
                },
                {
                  path: 'select-account',
                  action: async ({ request }) => {
                    const formData = await request.formData();
                    const publicKey = formData.get('publicKey');
                    invariant(typeof publicKey === 'string');

                    store.dispatch(
                      setSelectedAccount(publicKey ? publicKey : null)
                    );

                    return null;
                  },
                  element: null,
                },
                {
                  path: 'delete-account',
                  action: async ({ request }) => {
                    const formData = await request.formData();
                    const publicKey = formData.get('publicKey');
                    const walletType = formData.get('walletType');
                    invariant(typeof publicKey === 'string');
                    invariant(typeof walletType === 'string');

                    await store.dispatch(deleteAccount(publicKey));

                    if (walletType !== WalletType.Debug) {
                      track({
                        eventType: 'delete account',
                        walletType: walletType as WalletType,
                      });
                    }

                    return redirect('/add-account');
                  },
                  element: null,
                },
                {
                  path: 'update-account',
                  action: async ({ request }) => {
                    const formData = await request.formData();
                    const publicKey = formData.get('publicKey');
                    invariant(typeof publicKey === 'string');

                    const accountData: Partial<PrivateAccountData> =
                      Object.fromEntries(formData);

                    try {
                      await store.dispatch(
                        editAccount({ ...accountData, publicKey })
                      );
                    } catch (e) {
                      if (
                        e instanceof DOMException &&
                        e.name === 'ConstraintError'
                      ) {
                        return {
                          error: t(i18n)`Account name already exists`,
                        };
                      }
                    }

                    return null;
                  },
                  element: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
}
