import { BigNumber } from '@waves/bignumber';

import type { AssetDetails } from '../waves/redux';

export class Money {
  readonly assetInfo: AssetDetails;
  private readonly coins: BigNumber;

  private constructor(coins: BigNumber, assetInfo: AssetDetails) {
    this.coins = coins;
    this.assetInfo = assetInfo;
  }

  static fromCoins(
    coins: BigNumber | number | string,
    assetInfo: AssetDetails
  ) {
    return new Money(new BigNumber(coins), assetInfo);
  }

  static fromTokens(
    coins: BigNumber | number | string,
    assetInfo: AssetDetails
  ) {
    return new Money(
      new BigNumber(coins).mul(new BigNumber(10).pow(assetInfo.decimals)),
      assetInfo
    );
  }

  getCoins() {
    return this.coins;
  }

  getTokens() {
    return this.coins.mul(new BigNumber(10).pow(0 - this.assetInfo.decimals));
  }
}
