import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect, useState } from 'react';

import { CopyIcon } from '../icons/copy';
import { Button } from './button';
import * as styles from './copyButton.module.css';
import { Tooltip } from './tooltip';

interface CopyButtonProps {
  textToCopy: string;
  className?: string;
  children?: React.ReactNode;
}
export function CopyButton({
  children,
  textToCopy,
  className,
}: CopyButtonProps) {
  const { i18n } = useLingui();

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  return (
    <Tooltip
      content={isCopied ? t(i18n)`Copied` : t(i18n)`Copy`}
      placement="top"
    >
      {({ ref, onMouseEnter, onMouseLeave, onTouchStart }) => (
        <Button
          ref={ref}
          variant="icon"
          className={className}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onTouchStart={onTouchStart}
          onClick={() => {
            navigator.clipboard.writeText(textToCopy);
            setIsCopied(true);
          }}
        >
          <span className={styles.copyButtonContent}>
            {children}
            <CopyIcon className={styles.copyIcon} />
          </span>
        </Button>
      )}
    </Tooltip>
  );
}
