import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { type DBSchema, type IDBPDatabase, openDB } from 'idb';
import { applyMiddleware, createStore, type StoreEnhancer } from 'redux';
import thunk, { type ThunkDispatch } from 'redux-thunk';
import invariant from 'tiny-invariant';

import type { PrivateAccountData } from '../accounts/types';
import type { Cookies } from '../cookies/cookies';
import { type EmbedContext } from '../entry';
import { reducer } from './reducer';
import type { AppAction, AppState } from './types';

interface MainDBSchema extends DBSchema {
  accounts: {
    key: string;
    value: PrivateAccountData;
    indexes: {
      name: string;
    };
  };
}

export type AppThunkExtraArg = {
  cookies: Cookies;
  dataServiceUrl: string;
  useMainDb: <T>(
    performOperation: (db: IDBPDatabase<MainDBSchema>) => Promise<T>
  ) => Promise<T>;
};

type Migration = (db: IDBPDatabase) => Promise<void>;

const mainDbMigrations: Migration[] = [
  async (db: IDBPDatabase<unknown>) => {
    const accounts = db.createObjectStore('accounts', {
      keyPath: 'publicKey',
    });

    accounts.createIndex('name', 'name', { unique: true });
  },
];

export function createAppStore(
  {
    cookies,
    embedContext,
    dataServiceUrl,
  }: {
    cookies: Cookies;
    embedContext: EmbedContext;
    dataServiceUrl: string;
  },
  initialState?: AppState
) {
  const thunkExtraArg: AppThunkExtraArg = {
    cookies,
    dataServiceUrl,
    async useMainDb(performOperation) {
      invariant(
        embedContext !== 'iframe',
        'indexedDB must not be accessed from inside an iframe'
      );

      const db = await openDB<MainDBSchema>('main', mainDbMigrations.length, {
        async upgrade(upgradeDb, oldVersion, newVersion) {
          if (newVersion == null) {
            return;
          }

          for (let i = oldVersion; i < newVersion; i++) {
            await mainDbMigrations[i](upgradeDb as IDBPDatabase<unknown>);
          }
        },
      });

      try {
        return await performOperation(db);
      } finally {
        db.close();
      }
    },
  };

  const store = createStore<
    AppState,
    AppAction,
    { dispatch: ThunkDispatch<AppState, AppThunkExtraArg, AppAction> },
    Record<never, unknown>
  >(
    reducer,
    initialState,
    composeWithDevToolsDevelopmentOnly(
      applyMiddleware(thunk.withExtraArgument(thunkExtraArg))
    ) as StoreEnhancer<
      { dispatch: ThunkDispatch<AppState, AppThunkExtraArg, AppAction> },
      AppState
    >
  );

  import.meta.webpackHot?.accept('./reducer', () => {
    store.replaceReducer(reducer);
  });

  return store;
}
