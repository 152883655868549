import * as amplitude from '@amplitude/analytics-browser';
import mixpanel from 'mixpanel-browser';

import { type WalletType } from './accounts/types';

type AnalyticsEvent =
  | { eventType: 'page view'; pathname: string }
  | { eventType: 'add account'; walletType: WalletType }
  | { eventType: 'delete account'; walletType: WalletType }
  | {
      eventType: 'broadcast transaction';
      type: 'send assets' | 'swap assets';
      transactionId: string;
      walletType: WalletType;
    };

export type AnalyticsConfig = {
  amplitudeApiKey: string;
  mixpanelToken: string;
};

let globalAnalyticsConfig: AnalyticsConfig | undefined;

export function initAnalytics(config: AnalyticsConfig | undefined) {
  if (!config) return;

  const { amplitudeApiKey, mixpanelToken } = config;
  globalAnalyticsConfig = { amplitudeApiKey, mixpanelToken };

  if (amplitudeApiKey) {
    amplitude.init(amplitudeApiKey);
  }

  if (mixpanelToken) {
    mixpanel.init(mixpanelToken, {
      ignore_dnt: true,
      secure_cookie: true,
    });
  }
}

export function track({ eventType, ...properties }: AnalyticsEvent) {
  const eventProperties = { language: navigator.language, ...properties };

  amplitude.track(eventType, eventProperties);

  if (globalAnalyticsConfig?.mixpanelToken) {
    mixpanel.track(eventType, eventProperties);
  }
}
