import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type BigNumber } from '@waves/bignumber';

import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Skeleton } from '../_core/skeleton';
import { Table } from '../_core/table';
import { AssetCell } from '../assets/assetCell';
import { AssetList } from '../assets/assetList';
import * as styles from './recentAssets.module.css';
import { DashboardWidget, DashboardWidgetEmptyMessage } from './widget';

interface AssetItem {
  id: string;
  ticker: string;
  logo?: string;
  price?: BigNumber;
  priceChange?: number;
  available: BigNumber;
  worth?: BigNumber;
}

const NO_DATA_PLACEHOLDER = '–';

interface Props {
  className?: string;
  items: AssetItem[] | undefined;
}

export function RecentAssets({ className, items }: Props) {
  const { i18n } = useLingui();

  const recentItems = items?.slice(0, 5);

  return (
    <DashboardWidget
      className={className}
      heading={t(i18n)`Wallet`}
      headerLink={
        items == null || items.length === 0
          ? undefined
          : { label: items.length.toString(), to: '/portfolio' }
      }
    >
      {recentItems == null ? (
        <div className={styles.skeleton}>
          {new Array(5).fill(undefined).map((_, index) => (
            <Skeleton key={index} className={styles.skeletonItem} />
          ))}
        </div>
      ) : recentItems.length === 0 ? (
        <DashboardWidgetEmptyMessage
          heading={t(i18n)`Your wallet is empty`}
          description={t(i18n)`You have no assets yet.`}
        />
      ) : (
        <>
          <Table
            className={styles.assetTable}
            columns={[
              {
                label: t(i18n)`Asset`,
                render: ({ ticker, logo, id }) => (
                  <AssetCell ticker={ticker} logo={logo} id={id} />
                ),
              },
              {
                label: t(i18n)`Price`,
                render: ({ price }) =>
                  price ? formatUsdPrice(price) : `$${NO_DATA_PLACEHOLDER}`,
              },
              {
                label: t(i18n)`Balance`,
                render: ({ available }) => available.toFormat(),
                align: 'right',
              },
              {
                label: t(i18n)`USD Value`,
                render: ({ worth }) =>
                  worth ? formatUsdPrice(worth) : `$${NO_DATA_PLACEHOLDER}`,
                align: 'right',
              },
            ]}
            data={recentItems}
            getKey={row => row.id}
          />

          <AssetList items={recentItems} className={styles.assetList} />
        </>
      )}
    </DashboardWidget>
  );
}
