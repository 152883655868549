// extracted by mini-css-extract-plugin
export var card = "dX8BRAQ9XMJCZJ7paCg_";
export var cardHeader = "IAvaNKWf7mdxkswSHGwY";
export var cardSkeleton = "ZbRjVLgUNk4m6PGdtCgK";
export var mobileVendorProductGroup = "ABJG6iBQE5lalhgUwGjD";
export var mobileVendorProductGroups = "mW9lRZkiGzcS2BdmpOIW";
export var productBadge = "OzdgEwJeG_85BXOXPlYp";
export var productBadgelSkeleton = "SgvXL3SzYO9UT5j1rwSy";
export var productSkeleton = "jsX_Jw0KZdkngNl4CuD_";
export var vendorAnchor = "z6baZN_quMqvqULCxBC1";
export var vendorGroupAvatar = "JJregLIU3IzKwxKQc9QQ";
export var vendorGroupHeader = "U1Dunwwvk_jGamk7tsM9";
export var vendorGroupList = "GmGK0yBIas9Gdao2Q36f";
export var vendorGroupName = "hjLVP6lxYN1ibSCA0Bjf";
export var vendorGroupNameSkeleton = "yilv7LJGRR8RgKUBP2g1";
export var vendorGroupTotal = "zGuAHGyJHELr4cuh4exX";
export var vendorProductGroups = "nGlT_0m_864g8noLJuVR";