import { useEffect, useMemo } from 'react';
import invariant from 'tiny-invariant';

import { isAbortError } from '../_core/errors';
import { startPolling } from '../_core/polling';
import { isNotNull } from '../_core/predicates';
import {
  type DataServiceProtocol,
  fetchDataServiceAssetsAction,
  fetchDataServiceLeasesInfoAction,
  fetchDataServiceProductsAction,
  fetchDataServiceProtocolsAction,
  fetchDataServiceUsdPricesAction,
} from '../dataService/redux';
import { Network } from '../network/types';
import { useAppDispatch, useAppSelector } from '../store/react';
import {
  type AssetDetails,
  fetchWavesActiveLeasesAction,
  fetchWavesAssetsDetailsAction,
  fetchWavesBalancesAction,
  type WavesLeasingResponse,
} from '../waves/redux';
import { fetchWavesNftsAction } from '../waves/redux';

export function useDataServiceAssets({ network }: { network: Network }) {
  const dispatch = useAppDispatch();

  const assets = useAppSelector(state => state.cache.dataService.assets);

  useEffect(() => {
    if (network !== Network.Mainnet) return;

    const abortController = new AbortController();

    dispatch(
      fetchDataServiceAssetsAction({ signal: abortController.signal })
    ).catch(err => {
      if (isAbortError(err)) return;

      throw err;
    });

    return () => {
      abortController.abort();
    };
  }, [dispatch, network]);

  return useMemo(
    () => (network === Network.Mainnet ? assets : {}),
    [assets, network]
  );
}

export function useDataServiceProducts({
  addresses,
  network,
}: {
  addresses: string[] | undefined;
  network: Network;
}) {
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => state.cache.dataService.products);

  useEffect(() => {
    if (!addresses || network !== Network.Mainnet) return;

    return startPolling(5000, signal =>
      dispatch(
        fetchDataServiceProductsAction({
          addresses,
          signal,
        })
      )
    );
  }, [addresses, dispatch, network]);

  return useMemo(
    () =>
      network === Network.Mainnet
        ? Object.fromEntries(
            (addresses ?? []).map(address => [address, products[address]])
          )
        : {},
    [addresses, network, products]
  );
}

export function useDataServiceProtocols({ network }: { network: Network }) {
  const dispatch = useAppDispatch();
  const protocols = useAppSelector(state => state.cache.dataService.protocols);

  useEffect(() => {
    if (network !== Network.Mainnet) return;

    const abortController = new AbortController();

    dispatch(
      fetchDataServiceProtocolsAction({
        signal: abortController.signal,
      })
    ).catch(err => {
      if (isAbortError(err)) return;

      throw err;
    });

    return () => {
      abortController.abort();
    };
  }, [dispatch, network]);

  return useMemo(
    (): {
      list: DataServiceProtocol[];
      byProtocolId: Partial<Record<string, DataServiceProtocol>>;
    } | null =>
      network === Network.Mainnet
        ? protocols && {
            byProtocolId: Object.fromEntries<DataServiceProtocol | undefined>(
              protocols.map(protocol => [protocol.id, protocol])
            ),
            list: protocols,
          }
        : {
            byProtocolId: {},
            list: [],
          },
    [network, protocols]
  );
}

export function useDataServiceUsdPrices({
  assetIds,
  network,
}: {
  assetIds: string[];
  network: Network;
}) {
  const dispatch = useAppDispatch();
  const usdPrices = useAppSelector(state => state.cache.dataService.usdPrices);

  useEffect(() => {
    if (network !== Network.Mainnet) return;

    return startPolling(5000, signal =>
      dispatch(fetchDataServiceUsdPricesAction({ assetIds, signal }))
    );
  }, [assetIds, dispatch, network]);

  return useMemo(
    () =>
      network === Network.Mainnet
        ? Object.fromEntries(
            assetIds.map(assetId => [assetId, usdPrices[assetId]])
          )
        : {},
    [assetIds, network, usdPrices]
  );
}

export function useWavesAssetsDetails({
  assetIds,
  network,
}: {
  assetIds: string[];
  network: Network;
}) {
  const dispatch = useAppDispatch();

  const assetsDetails = useAppSelector(
    state => state.cache.waves.assetsDetails[network]
  );

  useEffect(() => {
    const abortController = new AbortController();

    dispatch(
      fetchWavesAssetsDetailsAction({
        assetIds,
        network,
        signal: abortController.signal,
      })
    ).catch(err => {
      if (isAbortError(err)) return;

      throw err;
    });

    return () => {
      abortController.abort();
    };
  }, [assetIds, dispatch, network]);

  return useMemo(
    () =>
      Object.fromEntries(
        assetIds.map(assetId => [assetId, assetsDetails[assetId]])
      ),
    [assetIds, assetsDetails]
  );
}

export function useWavesBalances({
  addresses,
  network,
}: {
  addresses: string[] | undefined;
  network: Network;
}) {
  const dispatch = useAppDispatch();
  const balances = useAppSelector(state => state.cache.waves.balances[network]);

  useEffect(() => {
    if (!addresses) return;

    return startPolling(5000, async signal => {
      await Promise.all(
        addresses.map(address =>
          dispatch(
            fetchWavesBalancesAction({
              address,
              network,
              signal,
            })
          )
        )
      );
    });
  }, [addresses, dispatch, network]);

  return useMemo(
    () =>
      Object.fromEntries(
        (addresses ?? []).map(address => {
          const addressBalance = balances[address];
          const byAssetId = addressBalance
            ? addressBalance.assetBalances
            : undefined;

          return [
            address,
            addressBalance && {
              wavesBalance: addressBalance.wavesBalance,
              byAssetId,
              list: Object.values(byAssetId ?? {}).filter(isNotNull),
            },
          ];
        })
      ),
    [addresses, balances]
  );
}

export function useWavesNfts({
  addresses,
  limit,
  network,
}: {
  addresses: string[] | undefined;
  limit: number;
  network: Network;
}) {
  const dispatch = useAppDispatch();

  const assetsDetails = useAppSelector(
    state => state.cache.waves.assetsDetails[network]
  );

  const nftsPerAddress = useAppSelector(
    state => state.cache.waves.nfts[network]
  );

  useEffect(() => {
    if (!addresses) return;

    return startPolling(5000, async signal => {
      await Promise.all(
        addresses.map(address =>
          dispatch(
            fetchWavesNftsAction({
              address,
              limit,
              network,
              signal,
            })
          )
        )
      );
    });
  }, [addresses, dispatch, limit, network]);

  return useMemo(
    (): { [address: string]: AssetDetails[] | undefined } =>
      Object.fromEntries(
        (addresses ?? []).map(address => {
          const nfts = nftsPerAddress[address];

          if (
            !nfts ||
            (nfts.largestRequestedLimit < limit && nfts.ids.length < limit)
          ) {
            return [address, undefined];
          }

          return [
            address,
            nfts.ids.slice(0, limit).map(nftId => {
              const assetDetails = assetsDetails[nftId];
              invariant(assetDetails);
              return assetDetails;
            }),
          ];
        })
      ),
    [addresses, assetsDetails, limit, nftsPerAddress]
  );
}

export function useWavesActiveLeases({
  addresses,
  network,
  only,
}: {
  addresses: string[] | undefined;
  network: Network;
  only?: 'incoming' | 'outgoing';
}): { [address: string]: WavesLeasingResponse[] | undefined } {
  const dispatch = useAppDispatch();
  const leases = useAppSelector(state => state.cache.waves.leases);

  useEffect(() => {
    if (!addresses || network !== Network.Mainnet) return;

    return startPolling(5000, async signal => {
      await Promise.all(
        addresses.map(address =>
          dispatch(
            fetchWavesActiveLeasesAction({
              network,
              address,
              signal,
            })
          )
        )
      );
    });
  }, [addresses, dispatch, network]);

  return useMemo(
    () =>
      network === Network.Mainnet
        ? Object.fromEntries(
            (addresses ?? []).map(address => {
              let filteredLeases = leases[network][address];

              if (only === 'incoming') {
                filteredLeases = filteredLeases?.filter(
                  leasing => leasing.recipient === address
                );
              } else if (only === 'outgoing') {
                filteredLeases = filteredLeases?.filter(
                  leasing => leasing.sender === address
                );
              }

              return [address, filteredLeases] as const;
            })
          )
        : {},
    [network, addresses, leases, only]
  );
}

export function useDataServiceLeasesInfo() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDataServiceLeasesInfoAction());
  }, [dispatch]);

  return useAppSelector(state => state.cache.dataService.leasesInfo);
}
