import clsx from 'clsx';

import * as styles from './table.module.css';

type Column<T> = {
  label?: string;
  render: (data: T) => React.ReactNode;
  align?: 'left' | 'center' | 'right';
  width?: number | string;
  minWidth?: number | string;
};

export function Table<T>({
  columns,
  data,
  onRowClick,
  className,
  getKey,
}: {
  columns: Array<Column<T>>;
  data: T[];
  onRowClick?(row: T): void;
  className?: string;
  getKey?(data: T): string;
}) {
  return (
    <table className={clsx(styles.table, className)}>
      <thead className={styles.tableHead}>
        <tr>
          {columns.map(({ label, align, width, minWidth }, index) => (
            <th
              key={index}
              className={clsx(styles.headCell, {
                [styles.alignCenter]: align === 'center',
                [styles.alignRight]: align === 'right',
              })}
              style={{ width, minWidth }}
            >
              {label}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => (
          <tr
            key={typeof getKey === 'function' ? getKey(item) : index}
            onClick={() => {
              onRowClick?.(item);
            }}
            className={clsx(styles.row, { [styles.clickable]: onRowClick })}
          >
            {columns.map(({ render, align }, colIndex) => (
              <td
                key={colIndex}
                className={clsx(styles.cell, {
                  [styles.alignCenter]: align === 'center',
                  [styles.alignRight]: align === 'right',
                })}
              >
                {render(item)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
