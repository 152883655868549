import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type BigNumber } from '@waves/bignumber';

import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Logo } from '../_core/logo';
import { Skeleton } from '../_core/skeleton';
import { Table } from '../_core/table';
import * as styles from './topInvestments.module.css';
import { DashboardWidget, DashboardWidgetEmptyMessage } from './widget';

export interface TopInvestmentsItem {
  productId: string;
  productName: string;
  productLogo: string | null;
  protocolId: string;
  protocolName: string;
  protocolLogo: string | null;
  totalWorth: BigNumber;
}

interface Props {
  className?: string;
  items: TopInvestmentsItem[] | undefined;
}

export function TopInvestments({ className, items }: Props) {
  const { i18n } = useLingui();

  return (
    <DashboardWidget
      className={className}
      heading={t(i18n)`Investments`}
      headerLink={
        items == null || items.length === 0
          ? undefined
          : {
              label: items.length.toString(),
              to: '/portfolio/investments',
            }
      }
    >
      {items == null ? (
        <div className={styles.skeleton}>
          {new Array(3).fill(undefined).map((_, index) => (
            <div key={index} className={styles.skeletonItem}>
              <Skeleton className={styles.productCellSkeleton} />
              <Skeleton className={styles.totalSkeleton} />
            </div>
          ))}
        </div>
      ) : items.length === 0 ? (
        <DashboardWidgetEmptyMessage
          heading={t(i18n)`No investments yet`}
          description={t(
            i18n
          )`Start investing in any connected dApp to see your portfolio`}
        />
      ) : (
        <Table
          columns={[
            {
              label: t(i18n)`Product`,
              render: row => (
                <ProductCell
                  id={row.productId}
                  logo={row.productLogo}
                  name={row.productName}
                  protocolId={row.protocolId}
                  protocolLogo={row.protocolLogo}
                  protocolName={row.protocolName}
                />
              ),
            },
            {
              label: t(i18n)`Total`,
              render: data => formatUsdPrice(data.totalWorth),
              align: 'right',
            },
          ]}
          data={items.slice(0, 3)}
        />
      )}
    </DashboardWidget>
  );
}

interface ProductCellProps {
  id: string;
  name: string;
  logo: string | null;
  protocolId: string;
  protocolName: string;
  protocolLogo: string | null;
}

function ProductCell({
  id,
  name,
  logo,
  protocolId,
  protocolName,
  protocolLogo,
}: ProductCellProps) {
  return (
    <div className={styles.productCell}>
      <Logo
        className={styles.productLogo}
        name={name}
        logo={logo}
        objectId={id}
      />

      <div>
        <h3 className={styles.productName}>{name}</h3>
        <div className={styles.protocolContainer}>
          <Logo
            className={styles.protocolLogo}
            name={protocolName}
            logo={protocolLogo}
            objectId={protocolId}
          />
          <span>{protocolName}</span>
        </div>
      </div>
    </div>
  );
}
