import { type I18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { init as initSentry } from '@sentry/browser';
import { StrictMode } from 'react';
import { hydrateRoot, type Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { pipe } from 'wonka';

import {
  acceptPortFrom,
  createRpcRequestHandler,
  createRpcRequestProxy,
} from './_core/rpc';
import { type AppApi, type ClientApi } from './_core/rpcTypes';
import { fromMessagePort, toMessagePort } from './_core/wonka';
import { readAccountsFromDB } from './accounts/redux';
import { initAnalytics } from './analytics';
import { BrowserCookies } from './cookies/cookies';
import { type EmbedContext, Entry, type EntryClient } from './entry';
import { activateLocale, getPreferredLocale, setupLocales } from './i18n';
import { createRoutes } from './routes';
import { sentryBeforeSend, updateIgnoreErrorsConfig } from './sentry';
import { createAppStore } from './store/create';

const { __ENTRY_CONTEXT__, __INITIAL_REDUX_STATE__ } = window;
invariant(__ENTRY_CONTEXT__);
const { css, js, nonce, dataServiceUrl, sentryConfig, analyticsConfig } =
  __ENTRY_CONTEXT__;
__webpack_nonce__ = nonce;

updateIgnoreErrorsConfig();

initAnalytics(analyticsConfig);
initSentry({ ...sentryConfig, beforeSend: sentryBeforeSend });

invariant(__INITIAL_REDUX_STATE__);
const initialReduxState = __INITIAL_REDUX_STATE__;

const embedContext: EmbedContext =
  parent !== window ? 'iframe' : opener != null ? 'window.open' : null;

const cookies = new BrowserCookies();

const store = createAppStore(
  {
    cookies,
    embedContext,
    dataServiceUrl,
  },
  initialReduxState
);

let root: Root | undefined;

function renderApp(i18n: I18n, client: EntryClient | null) {
  const routes = createRoutes({ i18n, store, sentryConfig });
  const router = createBrowserRouter(routes);

  if (import.meta.webpackHot) {
    const unsubscribe = router.subscribe(newRouterState => {
      window.__staticRouterHydrationData = newRouterState;
    });

    import.meta.webpackHot.accept(['./i18n', './routes'], async () => {
      unsubscribe();
      router.dispose();

      await activateLocale(i18n, i18n.locale);
      renderApp(i18n, client);
    });
  }

  const el = (
    <StrictMode>
      <Provider store={store}>
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
          <Entry
            client={client}
            css={css}
            dataServiceUrl={dataServiceUrl}
            embedContext={embedContext}
            initialReduxState={initialReduxState}
            js={js}
            nonce={nonce}
            origin={origin}
            routes={routes}
            router={router}
            sentryConfig={sentryConfig}
            analyticsConfig={analyticsConfig}
          >
            <RouterProvider router={router} />
          </Entry>
        </I18nProvider>
      </Provider>
    </StrictMode>
  );

  if (!root) {
    root = hydrateRoot(document, el);
  } else {
    root.render(el);

    if (import.meta.webpackHot) {
      router.navigate(router.state.location, {
        replace: true,
        preventScrollReset: true,
      });
    }
  }
}

Promise.resolve(setupLocales()).then(async i18n => {
  await activateLocale(i18n, getPreferredLocale(navigator.languages));
  renderApp(i18n, null);

  if (embedContext != null) {
    const port = await acceptPortFrom(embedContext === 'iframe' ? '*' : origin);

    const { api, proxy } = createRpcRequestProxy<ClientApi>();

    const { accumulatedRequests, handler } = createRpcRequestHandler<AppApi>(
      ({ accumulate }) => ({
        getPublicAccountData: accumulate(),
        getPrivateAccountData: accumulate(),
      })
    );

    pipe(fromMessagePort(port), proxy, toMessagePort(port));
    pipe(fromMessagePort(port), handler, toMessagePort(port));

    renderApp(i18n, { api, accumulatedRequests });
  }

  if (embedContext !== 'iframe') {
    await store.dispatch(readAccountsFromDB());
  }
});

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/sw.js');
}
