import { deepEqual } from 'fast-equals';
import { combineReducers, type Reducer } from 'redux';
import {
  array,
  boolean,
  type Infer,
  nullable,
  number,
  optional,
  record,
  string,
  type,
} from 'superstruct';

import { handleResponse } from '../_core/handleResponse';
import type { Money } from '../_core/money';
import type { AppThunkAction } from '../store/types';

const DataServiceAsset = type({
  id: string(),
  ticker: string(),
  url: string(),
});

type DataServiceAsset = Infer<typeof DataServiceAsset>;

export type DataServiceAssets = {
  [assetId: string]: DataServiceAsset | undefined;
};

const assetsReducer: Reducer<
  DataServiceAssets,
  {
    type: 'UPDATE_DATA_SERVICE_ASSETS';
    payload: {
      assets: DataServiceAsset[];
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_ASSETS': {
      return {
        ...state,
        ...Object.fromEntries(
          action.payload.assets.map(asset => [asset.id, asset])
        ),
      };
    }
    default:
      return state;
  }
};

const DataServiceProductResponse = type({
  product_id: number(),
  protocol_id: string(),
  address: string(),
  type: string(),
  name: string(),
  url: string(),
  icon_url: nullable(string()),
  amounts: array(
    type({
      asset_id: string(),
      coins: number(),
    })
  ),
});

type DataServiceProductResponse = Infer<typeof DataServiceProductResponse>;

export type DataServiceProduct = Omit<DataServiceProductResponse, 'amounts'> & {
  amounts: Money[];
};

type DataServiceProducts = {
  [address: string]: DataServiceProductResponse[] | undefined;
};

const productsReducer: Reducer<
  DataServiceProducts,
  {
    type: 'UPDATE_DATA_SERVICE_PRODUCTS';
    payload: {
      addresses: string[];
      products: DataServiceProductResponse[];
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_PRODUCTS': {
      const receivedProducts: DataServiceProducts = {};

      action.payload.products.forEach(product => {
        const products = (receivedProducts[product.address] ??= []);

        products.push(product);
      });

      const newState = {
        ...state,
        ...Object.fromEntries(
          action.payload.addresses.map(address => [
            address,
            receivedProducts[address] ?? [],
          ])
        ),
      };

      if (deepEqual(newState, state)) return state;

      return newState;
    }
    default:
      return state;
  }
};

const DataServiceProtocol = type({
  id: string(),
  name: string(),
  icon_url: string(),
  url: string(),
});

export type DataServiceProtocol = Infer<typeof DataServiceProtocol>;

const protocolsReducer: Reducer<
  DataServiceProtocol[] | null,
  {
    type: 'UPDATE_DATA_SERVICE_PROTOCOLS';
    payload: {
      protocols: DataServiceProtocol[];
    };
  }
> = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_PROTOCOLS':
      return action.payload.protocols;
    default:
      return state;
  }
};

const DataServiceUsdPrices = record(string(), optional(string()));

export type DataServiceUsdPrices = {
  [assetId: string]: string | undefined;
};

const usdPricesReducer: Reducer<
  DataServiceUsdPrices,
  {
    type: 'UPDATE_DATA_SERVICE_USD_PRICES';
    payload: {
      usdPrices: DataServiceUsdPrices;
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_USD_PRICES': {
      const newState = { ...state, ...action.payload.usdPrices };

      return deepEqual(newState, state) ? state : newState;
    }
    default:
      return state;
  }
};

const DataServiceLeasingInfo = type({
  address: string(),
  name: string(),
  description: string(),
  icon_url: string(),
  url: string(),
  deleted: boolean(),
});

export type DataServiceLeasingInfo = Infer<typeof DataServiceLeasingInfo>;
export type DataServiceLeasingInfoByAddress = Partial<
  Record<string, DataServiceLeasingInfo>
>;

const leasesInfoReducer: Reducer<
  DataServiceLeasingInfoByAddress,
  {
    type: 'UPDATE_DATA_SERVICE_LEASES_INFO';
    payload: {
      leasesInfo: DataServiceLeasingInfo[];
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_LEASES_INFO': {
      return Object.fromEntries(
        action.payload.leasesInfo.map(info => [info.address, info])
      );
    }
    default:
      return state;
  }
};

export default combineReducers({
  assets: assetsReducer,
  products: productsReducer,
  protocols: protocolsReducer,
  usdPrices: usdPricesReducer,
  leasesInfo: leasesInfoReducer,
});

export function fetchDataServiceAssetsAction({
  signal,
}: {
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { dataServiceUrl }) => {
    const assets = await fetch(new URL('/api/v1/assets', dataServiceUrl), {
      signal,
    }).then(handleResponse(array(DataServiceAsset)));

    dispatch({
      type: 'UPDATE_DATA_SERVICE_ASSETS',
      payload: {
        assets,
      },
    });
  };
}

const knownDataServiceProductTypes = [
  'staking',
  'liquidity_pool',
  'lend',
] as const;

export function fetchDataServiceProductsAction({
  addresses,
  signal,
}: {
  addresses: string[];
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { dataServiceUrl }) => {
    if (addresses.length === 0) return;

    const products = await fetch(new URL('/api/v1/portfolio', dataServiceUrl), {
      method: 'POST',
      body: JSON.stringify({ addresses }),
      signal,
    })
      .then(handleResponse(array(DataServiceProductResponse)))
      .then(dataServiceProducts =>
        dataServiceProducts.filter(product =>
          knownDataServiceProductTypes.some(
            knownType => knownType === product.type
          )
        )
      );

    dispatch({
      type: 'UPDATE_DATA_SERVICE_PRODUCTS',
      payload: {
        addresses,
        products,
      },
    });
  };
}

export function fetchDataServiceProtocolsAction({
  signal,
}: {
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { dataServiceUrl }) => {
    dispatch({
      type: 'UPDATE_DATA_SERVICE_PROTOCOLS',
      payload: {
        protocols: await fetch(new URL('/api/v1/protocols', dataServiceUrl), {
          signal,
        }).then(handleResponse(array(DataServiceProtocol))),
      },
    });
  };
}

export function fetchDataServiceUsdPricesAction({
  assetIds,
  signal,
}: {
  assetIds: string[];
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { dataServiceUrl }) => {
    if (assetIds.length === 0) return;

    const usdPrices = await fetch(new URL('/api/v1/rates', dataServiceUrl), {
      method: 'POST',
      body: JSON.stringify({ ids: assetIds }),
      signal,
    }).then(handleResponse(DataServiceUsdPrices));

    dispatch({
      type: 'UPDATE_DATA_SERVICE_USD_PRICES',
      payload: {
        usdPrices,
      },
    });
  };
}

export function fetchDataServiceLeasesInfoAction({
  signal,
}: { signal?: AbortSignal } = {}): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { dataServiceUrl }) => {
    const leasesInfo = await fetch(new URL('/api/v1/lease', dataServiceUrl), {
      signal,
    }).then(handleResponse(array(DataServiceLeasingInfo)));

    dispatch({
      type: 'UPDATE_DATA_SERVICE_LEASES_INFO',
      payload: {
        leasesInfo,
      },
    });
  };
}
