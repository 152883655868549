import { Logo } from '../_core/logo';
import * as styles from './productCell.module.css';

export function ProductCell({
  id,
  logo,
  name,
}: {
  id: string;
  logo: string | null | undefined;
  name: string;
}) {
  return (
    <div className={styles.productCell}>
      <Logo logo={logo} name={name} size={24} objectId={id} />
      <span className={styles.productCellTitle}>{name}</span>
    </div>
  );
}
