import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect, useState } from 'react';
import {
  fromPromise,
  map,
  merge,
  pipe,
  subscribe,
  take,
  toPromise,
} from 'wonka';

import { Button } from '../_core/button';
import { FormHelperText } from '../_core/formHelperText';
import {
  type AccumulatedRpcRequest,
  createRpcRequestProxy,
  passPortTo,
} from '../_core/rpc';
import { type AppApi } from '../_core/rpcTypes';
import { Spinner } from '../_core/spinner';
import {
  fromMessagePort,
  fromWindowEvent,
  toMessagePort,
} from '../_core/wonka';
import { type PublicAccountData } from '../accounts/types';
import { useEntryContext } from '../entry';
import { Container } from '../layout/layout';
import { useAppDispatch, useAppSelector } from '../store/react';
import * as styles from './iframe.module.css';

export function IframePage() {
  const { client, embedContext } = useEntryContext();
  const { i18n } = useLingui();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const [connectWalletRequest, setConnectWalletRequest] =
    useState<AccumulatedRpcRequest<AppApi, 'getPublicAccountData'> | null>(
      null
    );

  useEffect(() => {
    if (embedContext !== 'iframe') {
      const url = new URL(location.href);
      url.pathname = '/';
      url.search = '';
      url.hash = '';
      location.href = url.href;
      return;
    }

    if (!client) return;

    const { unsubscribe } = pipe(
      client.accumulatedRequests.getPublicAccountData,
      subscribe(request => {
        client.api.show();
        setConnectWalletRequest(request);
      })
    );

    return () => {
      unsubscribe();
    };
  }, [client, embedContext]);

  if (!client || !connectWalletRequest) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner size={32} />
      </div>
    );
  }

  return (
    <Container>
      <form
        className={styles.root}
        onSubmit={async event => {
          event.preventDefault();

          setIsSubmitting(true);

          try {
            const requestWalletAccessUrl = new URL(
              '/request-wallet-access',
              origin
            );

            requestWalletAccessUrl.searchParams.set('network', network);

            const requestWalletAccessWindow = window.open(
              requestWalletAccessUrl
            );

            if (!requestWalletAccessWindow) {
              throw new Error(t(i18n)`Window is blocked`);
            }

            const { port1, port2 } = new MessageChannel();
            const { api, proxy } = createRpcRequestProxy<AppApi>();

            pipe(fromMessagePort(port1), proxy, toMessagePort(port1));

            await pipe(
              fromWindowEvent(requestWalletAccessWindow, 'load'),
              take(1),
              toPromise
            );

            passPortTo(requestWalletAccessWindow, origin, port2);

            const account = await pipe(
              merge([
                pipe(
                  fromWindowEvent(requestWalletAccessWindow, 'pagehide'),
                  map(() => null)
                ),
                fromPromise(api.getPrivateAccountData()),
              ]),
              take(1),
              toPromise
            );

            requestWalletAccessWindow.close();

            if (account) {
              dispatch({
                type: 'SET_ACCOUNTS',
                payload: [account],
              });

              const publicAccountData: PublicAccountData = {
                publicKey: account.publicKey,
              };

              connectWalletRequest.resolve(publicAccountData);
            }
          } catch (err) {
            setFormError(
              typeof err === 'object' &&
                err &&
                'message' in err &&
                typeof err.message === 'string'
                ? err.message
                : t(i18n)`Unexpected error`
            );
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        <div className={styles.main}>
          <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
            <Trans>All Accounts</Trans>
          </Button>

          <FormHelperText hasError>{!isSubmitting && formError}</FormHelperText>
        </div>
      </form>
    </Container>
  );
}
