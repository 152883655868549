import { type EventHint } from '@sentry/browser';
import { type ErrorEvent } from '@sentry/types';

import * as defaultIgnoreErrors from '../public/sentry-ignore-errors.json';
import { isAbortError } from './_core/errors';

const ignoreErrorsConfig = { ...defaultIgnoreErrors };

export async function updateIgnoreErrorsConfig(intervalMs = 60_000) {
  const res = await fetch('/sentry-ignore-errors.json');

  const { ignoreAll, ignoreErrors }: typeof ignoreErrorsConfig =
    await res.json();

  ignoreErrorsConfig.ignoreAll = ignoreAll;
  ignoreErrorsConfig.ignoreErrors = ignoreErrors;

  setTimeout(updateIgnoreErrorsConfig, intervalMs);
}

export async function sentryBeforeSend(event: ErrorEvent, hint: EventHint) {
  const message =
    hint &&
    hint.originalException &&
    typeof hint.originalException === 'object' &&
    'message' in hint.originalException &&
    typeof hint.originalException.message === 'string' &&
    hint.originalException.message
      ? hint.originalException.message
      : String(hint?.originalException);

  if (
    isAbortError(hint.originalException) ||
    ignoreErrorsConfig.ignoreAll ||
    ignoreErrorsConfig.ignoreErrors.some(str => {
      const re = new RegExp(str);

      return re.test(message);
    })
  ) {
    return null;
  }

  return event;
}
