// extracted by mini-css-extract-plugin
export var buttonIcon = "sum96VHNBmrYfZwnJ6lQ";
export var floating = "OFDU90QGWFvc5cnDLt7N";
export var option = "T7m68BuyLiyE_YIhAtPQ";
export var optionActive = "Z47ZwWE7OzxQ7Kfbf4pk";
export var optionSelectedIcon = "tVRmseBV6sM9hSmBZ_kw";
export var placeholder = "oDFlO9Gov6NbMMQvAnqp";
export var select = "A6Rx5kvReLhxpg_mASYY";
export var select_autoWidth = "Fl9he5zMdX_ZnilahXVS";
export var select_variant_default = "DaNWC5bNRz_Xc40QDezg";
export var select_variant_defaultSmall = "PFzTFxg25Q8CDvs5Z9Lh";
export var select_variant_minimal = "LjreaZNYYZB2MnC6XGym";