import clsx from 'clsx';

import * as styles from './formHelperText.module.css';

interface Props {
  children: React.ReactNode | undefined;
  hasError?: boolean;
  className?: string;
}

export function FormHelperText({ children, hasError, className }: Props) {
  return children ? (
    <span
      className={clsx(className, styles.root, {
        [styles.root_isError]: hasError,
      })}
    >
      {children}
    </span>
  ) : null;
}
