import { BigNumber } from '@waves/bignumber';
import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Skeleton } from '../_core/skeleton';
import { Tooltip } from '../_core/tooltip';
import * as styles from './investmentsOverview.module.css';

export interface InvestmentsOverviewItem {
  id: string;
  value: BigNumber;
  logo: string;
  name: string;
  pinned?: boolean;
  link?: string;
}

interface DAppProps {
  active: boolean;
  value: BigNumber;
  logo: string;
  name: string;
  color?: string;
  link?: string;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onClick?(): void;
}

const DAppContainer = ({
  children,
  link,
}: {
  children: React.ReactNode;
  link?: string;
}) => (link ? <Link to={link}>{children}</Link> : <>{children}</>);

function DApp({
  name,
  value,
  logo,
  color,
  active,
  link,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: DAppProps) {
  return (
    <DAppContainer link={link}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={clsx(styles.dApp, {
          [styles.dApp_active]: active,
          [styles.clickable]: onClick || link,
        })}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
      >
        <div className={styles.dAppLogo}>
          {logo && <img src={logo} alt="" />}
          {color && (
            <div
              className={styles.dAppLogoBadge}
              style={{ backgroundColor: color }}
            />
          )}
        </div>
        <div className={styles.dAppContent}>
          <h3 className={styles.dAppTitle}>{name}</h3>
          <div className={styles.dAppValue}>{formatUsdPrice(value)}</div>
        </div>
      </div>
    </DAppContainer>
  );
}

const COLORS = ['#1f5af6', '#7e1ff6', '#f8213a', '#fe8a04', '#009e3f'];

interface Props {
  investments: InvestmentsOverviewItem[] | undefined;
}

export function InvestmentsOverview({ investments }: Props) {
  const [active, setActive] = useState<string | null>(null);
  const [areOtherDAppsOpen, setAreOtherDAppsOpen] = useState(false);

  if (investments == null) {
    return (
      <div className={styles.skeleton}>
        <Skeleton className={styles.stackSkeleton} />
        <Skeleton className={styles.itemsSkeleton} />
      </div>
    );
  }

  const investmentsSorted = investments
    .map(({ value, ...investment }, index) => ({
      ...investment,
      value: new BigNumber(value),
      color: COLORS[index % COLORS.length],
    }))
    .sort((a, b) => {
      if (a.pinned || b.pinned) {
        return 0;
      }

      if (a.value.lt(b.value)) {
        return 1;
      }

      if (a.value.gt(b.value)) {
        return -1;
      }

      return 0;
    });

  const hasOther = investments.length > 12;

  const totalSum = investmentsSorted.reduce(
    (acc, { value }) => acc.add(value),
    new BigNumber(0)
  );

  const mainDApps = investmentsSorted.slice(0, hasOther ? 11 : 12);

  const otherDApps = hasOther ? investmentsSorted.slice(11) : [];

  const otherDAppsSum = otherDApps.reduce(
    (acc, { value }) => acc.add(value),
    new BigNumber(0)
  );

  const otherDAppsPercent = new BigNumber(100).div(totalSum).mul(otherDAppsSum);

  return (
    <>
      <div className={styles.stack}>
        {mainDApps.map(item => {
          const percent = totalSum.gt(0)
            ? new BigNumber(100).div(totalSum).mul(item.value)
            : new BigNumber(0);

          const isActive = item.id === active;

          return (
            <Tooltip
              key={item.id}
              content={`${item.name} (${percent.roundTo(2)}%)`}
              isOpen={isActive}
              placement="top"
            >
              {({ ref, onMouseEnter, onMouseLeave }) => (
                <div
                  ref={ref}
                  className={clsx(styles.stackItem, {
                    [styles.stackItem_active]: isActive,
                  })}
                  style={{ width: `${percent}%` }}
                  onMouseEnter={() => {
                    setActive(item.id);
                    onMouseEnter();
                  }}
                  onMouseLeave={() => {
                    setActive(null);
                    onMouseLeave();
                  }}
                >
                  <div
                    className={styles.stackItemFill}
                    style={{ backgroundColor: item.color }}
                  />
                </div>
              )}
            </Tooltip>
          );
        })}

        {otherDApps.length > 0 && (
          <Tooltip
            content={`Other dApps (${otherDAppsPercent.roundTo(2)}%)`}
            isOpen={active === 'other'}
            placement="top"
          >
            {({ ref, onMouseEnter, onMouseLeave }) => (
              <div
                ref={ref}
                className={clsx(styles.stackItem, {
                  [styles.stackItem_active]: active === 'other',
                })}
                style={{ width: `${otherDAppsPercent}%` }}
                onMouseEnter={() => {
                  setActive('other');
                  onMouseEnter();
                }}
                onMouseLeave={() => {
                  setActive(null);
                  onMouseLeave();
                }}
              >
                <div
                  className={styles.stackItemFill}
                  style={{ backgroundColor: '#7E1FF6' }}
                />
              </div>
            )}
          </Tooltip>
        )}
      </div>

      <div className={clsx(styles.dAppsGrid, styles.dApps)}>
        {mainDApps.map(item => (
          <DApp
            {...item}
            key={item.id}
            active={item.id === active}
            link={item.link}
            onMouseEnter={() => setActive(item.id)}
            onMouseLeave={() => setActive(null)}
          />
        ))}

        {otherDApps.length > 0 && (
          <DApp
            value={otherDAppsSum}
            name={`Other dApps (${otherDApps.length})`}
            color="#7E1FF6"
            logo={new URL('./images/other-dapps.svg', import.meta.url).pathname}
            active={'other' === active || areOtherDAppsOpen}
            onMouseEnter={() => setActive('other')}
            onMouseLeave={() => setActive(null)}
            onClick={() => setAreOtherDAppsOpen(prev => !prev)}
          />
        )}
      </div>

      {areOtherDAppsOpen && (
        <div className={clsx(styles.dAppsGrid, styles.otherDApps)}>
          {otherDApps.map((item, index) => (
            <DApp
              {...item}
              color={undefined}
              key={index}
              active={item.id === active}
              link={item.link}
            />
          ))}
        </div>
      )}
    </>
  );
}
