import clsx from 'clsx';
import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ReactElement,
  type ReactNode,
} from 'react';
import {
  type NavLinkProps,
  useHref,
  useLinkClickHandler,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';

import * as styles from './menu.module.css';

interface MenuProps {
  label?: string;
  children: ReactNode;
}

export function Menu({ label, children }: MenuProps) {
  return (
    <div className={styles.menuSection}>
      {label && <h3 className={styles.menuSection_title}>{label}</h3>}

      <ul className={styles.menuSection_list}>{children}</ul>
    </div>
  );
}

type MenuItemButtonProps = Omit<
  React.DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'children'
> & {
  label: string;
  icon?: ReactElement;
  badge?: ReactElement | string;
};

export function MenuItemButton({
  label,
  icon,
  badge,
  className,
  ...buttonProps
}: MenuItemButtonProps) {
  return (
    <li>
      <button {...buttonProps} className={clsx(styles.menuItem, className)}>
        {icon && <span className={styles.menuItem_icon}>{icon}</span>}

        <span>{label}</span>

        {badge && <span className={styles.menuItem_badge}>{badge}</span>}
      </button>
    </li>
  );
}

type MenuItemLinkProps = Omit<
  React.DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  'children'
> &
  Pick<NavLinkProps, 'to' | 'relative'> & {
    label: string;
    icon?: ReactElement;
    badge?: ReactElement | string;
    disabled?: boolean;
  };

export function MenuItemLink({
  label,
  icon,
  badge,
  disabled,
  to,
  relative,
  className,
  onClick,
  ...navLinkProps
}: MenuItemLinkProps) {
  const href = useHref(to);
  const linkClickHandler = useLinkClickHandler(to);

  const path = useResolvedPath(to, { relative });
  const location = useLocation();
  const toPathname = path.pathname;
  const locationPathname = location.pathname;

  const isActive =
    locationPathname === toPathname ||
    (locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/');

  return (
    <li>
      <a
        {...navLinkProps}
        rel="noreferrer"
        href={disabled ? undefined : href}
        className={clsx(
          styles.menuItem,
          {
            [styles.menuItem_active]: isActive,
          },
          className
        )}
        onClick={
          disabled
            ? undefined
            : event => {
                onClick?.(event);
                linkClickHandler(event);
              }
        }
      >
        {icon && <span className={styles.menuItem_icon}>{icon}</span>}

        <span>{label}</span>

        {badge && <span className={styles.menuItem_badge}>{badge}</span>}
      </a>
    </li>
  );
}
