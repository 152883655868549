import {
  base58Decode,
  base58Encode,
  createAddress,
} from '@keeper-wallet/waves-crypto';

export function ellipsis(value: string, size = 5) {
  return `${value.slice(0, size)}...${value.slice(-size)}`;
}

export function encodeAddressFromPublicKey(publicKey: string, chainId: number) {
  return base58Encode(createAddress(base58Decode(publicKey), chainId));
}
