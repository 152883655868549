export interface PublicAccountData {
  publicKey: string;
}

export enum WalletType {
  KeeperExtension = 'keeper-extension',
  KeeperMobile = 'keeper-mobile',
  Debug = 'debug',
}

export type PrivateAccountData = PublicAccountData & { name: string } & (
    | { walletType: WalletType.KeeperExtension }
    | { walletType: WalletType.KeeperMobile }
    | { walletType: WalletType.Debug }
  );
