import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type BigNumber } from '@waves/bignumber';

import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Skeleton } from '../_core/skeleton';
import { Table } from '../_core/table';
import { AssetCell } from '../assets/assetCell';
import { AssetList } from '../assets/assetList';
import { TabPanelHeader } from './tabPanelHeader';
import * as styles from './wallet.module.css';

const NO_DATA_PLACEHOLDER = '–';

interface Props {
  assetBalances:
    | Array<{
        id: string;
        ticker: string;
        logo?: string;
        price?: BigNumber;
        priceChange?: number;
        available: BigNumber;
        worth?: BigNumber;
        total?: BigNumber;
      }>
    | undefined;
  worth: BigNumber | undefined;
}

export function PortfolioWallet({ assetBalances, worth }: Props) {
  const { i18n } = useLingui();

  const isLoading = assetBalances == null || worth == null;

  return (
    <div>
      <TabPanelHeader
        caption={t(i18n)`Wallet worth`}
        heading={isLoading ? null : formatUsdPrice(worth)}
      />

      <div className={styles.assetListContainer}>
        {isLoading ? (
          <div className={styles.skeleton}>
            {new Array(5).fill(undefined).map((_, index) => (
              <Skeleton key={index} className={styles.skeletonItem} />
            ))}
          </div>
        ) : (
          <>
            <Table
              className={styles.assetTable}
              columns={[
                {
                  label: t(i18n)`Asset`,
                  render: ({ ticker, logo, id }) => (
                    <AssetCell ticker={ticker} logo={logo} id={id} />
                  ),
                  minWidth: 150,
                },
                {
                  label: t(i18n)`Price`,
                  render: ({ price }) =>
                    price ? formatUsdPrice(price) : `$${NO_DATA_PLACEHOLDER}`,
                },
                {
                  label: t(i18n)`Balance`,
                  render: ({ available, total }) => (
                    <>
                      {available.toFormat()}
                      <br />
                      {total && (
                        <span className={styles.totalValue}>
                          of total {total.toFormat()}
                        </span>
                      )}
                    </>
                  ),
                  align: 'right',
                },
                {
                  label: t(i18n)`USD Value`,
                  render: balance => (
                    <span>
                      {balance.worth
                        ? formatUsdPrice(balance.worth)
                        : `$${NO_DATA_PLACEHOLDER}`}
                      <br />
                      {balance.total && balance.price && (
                        <span className={styles.totalValue}>
                          of total{' '}
                          {formatUsdPrice(balance.total.mul(balance.price))}
                        </span>
                      )}
                    </span>
                  ),
                  align: 'right',
                },
              ]}
              data={assetBalances}
            />

            <AssetList items={assetBalances} className={styles.assetList} />
          </>
        )}
      </div>
    </div>
  );
}
