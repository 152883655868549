import { Skeleton } from '../_core/skeleton';
import * as styles from './tabPanelHeader.module.css';

interface Props {
  caption?: string;
  heading: React.ReactNode;
}

export function TabPanelHeader({ caption, heading }: Props) {
  return (
    <header className={styles.root}>
      {caption && <h2 className={styles.caption}>{caption}</h2>}

      {heading == null ? (
        <Skeleton className={styles.headingSkeleton} />
      ) : (
        <div className={styles.heading}>{heading}</div>
      )}
    </header>
  );
}
