import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';

import { Menu, MenuItemLink } from '../_core/menu';
import { ConnectWalletButton } from '../accounts/connectWalletButton';
import { ConnectionIcon } from '../icons/connection';
import { HistoryIcon } from '../icons/history';
import { HomeIcon } from '../icons/home';
import { PortfolioIcon } from '../icons/portfolio';
import { SendIcon } from '../icons/send';
import { SwapIcon } from '../icons/swap';
import { useAppSelector } from '../store/react';
import * as styles from './sidebar.module.css';

interface Props {
  className?: string;
  closeMobileMenu?: () => void;
}

export function Sidebar({ className, closeMobileMenu }: Props) {
  const { i18n } = useLingui();
  const accounts = useAppSelector(state => state.accounts);

  return (
    <aside className={clsx(styles.root, className)}>
      {accounts && (
        <div className={styles.currentAccountSelect}>
          <ConnectWalletButton onClick={closeMobileMenu} />
        </div>
      )}

      <nav className={styles.nav}>
        <Menu>
          <MenuItemLink
            label={t(i18n)({ comment: 'Menu item', message: 'Dashboard' })}
            to="/"
            icon={<HomeIcon />}
            onClick={closeMobileMenu}
          />
          <MenuItemLink
            label={t(i18n)({ comment: 'Menu item', message: 'Portfolio' })}
            to="/portfolio"
            icon={<PortfolioIcon />}
            onClick={closeMobileMenu}
          />
        </Menu>

        <Menu label={t(i18n)({ comment: 'Menu title', message: 'Assets' })}>
          <MenuItemLink
            label={t(i18n)({ comment: 'Menu item', message: 'Swap' })}
            to="/swap"
            icon={<SwapIcon />}
            onClick={closeMobileMenu}
          />
          <MenuItemLink
            label={t(i18n)({ comment: 'Menu item', message: 'Send' })}
            to="/send"
            icon={<SendIcon />}
            onClick={closeMobileMenu}
          />
        </Menu>

        <Menu label={t(i18n)({ comment: 'Menu title', message: 'Profile' })}>
          <MenuItemLink
            label={t(i18n)({ comment: 'Menu item', message: 'Connections' })}
            to="/profile/connections"
            icon={<ConnectionIcon />}
            badge={t(i18n)`Soon`}
            disabled
            onClick={closeMobileMenu}
          />
          <MenuItemLink
            label={t(i18n)({ comment: 'Menu item', message: 'History' })}
            to="/profile/history"
            icon={<HistoryIcon />}
            badge={t(i18n)`Soon`}
            disabled
            onClick={closeMobileMenu}
          />
        </Menu>
      </nav>
    </aside>
  );
}
