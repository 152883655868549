import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { type Link, useHref, useLinkClickHandler } from 'react-router-dom';

import * as styles from './button.module.css';
import { Spinner } from './spinner';

function ButtonContent({
  children,
  loading,
}: {
  children: React.ReactNode;
  loading?: boolean;
}) {
  return (
    <>
      <span className={styles.children}>{children}</span>

      {loading && (
        <span className={styles.spinnerWrapper}>
          <Spinner className={styles.spinner} size={16} />
        </span>
      )}
    </>
  );
}

function getButtonClassName({
  className,
  variant = 'default',
  loading,
  disabled,
}: CommonProps & { className?: string }) {
  return clsx(
    className,
    styles.btn,
    {
      [styles.btn_loading]: loading,
      [styles.disabled]: disabled,
    },
    {
      default: styles.btn_variant_default,
      danger: styles.btn_variant_danger,
      icon: styles.btn_variant_icon,
      icon_danger: styles.btn_variant_icon_danger,
      outlined: styles.btn_variant_outlined,
      outlined_danger: styles.btn_variant_outlined_danger,
    }[variant]
  );
}

type CommonProps = {
  variant?:
    | 'default'
    | 'danger'
    | 'icon'
    | 'icon_danger'
    | 'outlined'
    | 'outlined_danger';
  loading?: boolean;
  disabled?: boolean;
};

type ButtonProps = React.ComponentProps<'button'> & CommonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    { variant = 'default', loading, className, children, ...props },
    ref
  ) {
    return (
      <button
        {...props}
        ref={ref}
        className={getButtonClassName({
          className,
          variant,
          loading,
          disabled: props.disabled,
        })}
      >
        <ButtonContent>{children}</ButtonContent>
      </button>
    );
  }
);

type LinkButtonProps = React.ComponentProps<typeof Link> & CommonProps;

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  function LinkButton(
    {
      variant = 'default',
      loading,
      className,
      children,
      disabled,
      onClick,
      to,
      ...props
    },
    ref
  ) {
    const linkClickHandler = useLinkClickHandler(to);
    const href = useHref(to);

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        {...props}
        ref={ref}
        className={getButtonClassName({
          className,
          variant,
          loading,
          disabled,
        })}
        href={disabled ? undefined : href}
        onClick={
          disabled
            ? undefined
            : event => {
                onClick?.(event);
                linkClickHandler(event);
              }
        }
      >
        <ButtonContent>{children}</ButtonContent>
      </a>
    );
  }
);

type AnchorButtonProps = React.ComponentProps<'a'> & CommonProps;

export const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  function AnchorButton(
    {
      variant = 'default',
      loading,
      className,
      children,
      href,
      disabled,
      onClick,
      ...props
    },
    ref
  ) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        {...props}
        href={disabled ? undefined : href}
        onClick={disabled ? undefined : onClick}
        ref={ref}
        className={getButtonClassName({
          className,
          variant,
          loading,
          disabled,
        })}
      >
        <ButtonContent>{children}</ButtonContent>
      </a>
    );
  }
);
