import { type Network } from './types';

interface WavesNetworkConfig {
  chainId: number;
  nodeUrl: string;
}

export const WAVES_NETWORK_CONFIGS: Record<Network, WavesNetworkConfig> = {
  mainnet: {
    chainId: 87,
    nodeUrl: 'https://nodes-keeper.wavesnodes.com',
  },
  testnet: {
    chainId: 84,
    nodeUrl: 'https://nodes-testnet.wavesnodes.com',
  },
};
