import { base58Decode, verifyAddress } from '@keeper-wallet/waves-crypto';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import BigNumber from '@waves/bignumber';
import { TRANSACTION_TYPE } from '@waves/ts-types';
import { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { nullable, number, record, string, type } from 'superstruct';

import { Boundary } from '../_core/boundary';
import { Button } from '../_core/button';
import { getFeeOptions } from '../_core/fee';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { FormControlProvider } from '../_core/formControl';
import { FormHelperText } from '../_core/formHelperText';
import { FormLabel } from '../_core/formLabel';
import { handleResponse } from '../_core/handleResponse';
import { Input } from '../_core/input';
import { Logo } from '../_core/logo';
import { Money } from '../_core/money';
import { isNotNull } from '../_core/predicates';
import { Select } from '../_core/select';
import { Spinner } from '../_core/spinner';
import { useDebouncedValue } from '../_core/useDebouncedValue';
import { ellipsis, encodeAddressFromPublicKey } from '../_core/utils';
import { type PrivateAccountData } from '../accounts/types';
import type {
  DataServiceAssets,
  DataServiceUsdPrices,
} from '../dataService/redux';
import { WalletIcon } from '../icons/wallet';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { type Network } from '../network/types';
import { useAppSelector } from '../store/react';
import {
  type AssetDetails,
  type AssetDetailsRecord,
  WAVES_ASSET_DETAILS,
  type WavesAssetBalanceResponse,
} from '../waves/redux';
import * as styles from './sendAssets.module.css';

const DEFAULT_FEE_IN_WAVES = '100000';

interface TransferData {
  sender: Pick<PrivateAccountData, 'name' | 'publicKey' | 'walletType'>;
  amount: Money;
  recipient: string;
  fee: Money;
}

const ScriptInfo = type({
  address: string(),
  script: nullable(string()),
  scriptText: nullable(string()),
  version: nullable(number()),
  complexity: number(),
  verifierComplexity: number(),
  callableComplexities: record(string(), number()),
  extraFee: number(),
});

async function fetchScriptInfo(
  network: Network,
  address: string,
  { signal }: { signal?: AbortSignal } = {}
) {
  const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  return fetch(new URL(`/addresses/scriptInfo/${address}`, nodeUrl), {
    signal,
  }).then(handleResponse(ScriptInfo));
}

interface Props {
  values: ReturnType<typeof useSendAssets>['values'];
  setValues: ReturnType<typeof useSendAssets>['setValues'];
  accounts: PrivateAccountData[];
  balancesByAddresses: Partial<Record<string, WavesAssetBalanceResponse[]>>;
  assets: AssetDetailsRecord;
  assetsInfo: DataServiceAssets;
  usdPrices: DataServiceUsdPrices;
  formError?: string;
  onSubmit: (transferData: TransferData) => void;
}

const DEFAULT_VALUES = {
  assetId: '',
  senderPublicKey: '',
  recipient: '',
  recipientAddress: '',
  recipientTouched: false,
  amount: { value: '', formattedValue: '' },
  amountTouched: false,
  feeAssetId: '',
};

export function useSendAssets(
  defaultValues: Partial<typeof DEFAULT_VALUES> = DEFAULT_VALUES
) {
  const initialValues = { ...DEFAULT_VALUES, ...defaultValues };

  const [values, setValues] = useState(initialValues);

  return {
    values,
    setValues,
    resetValues() {
      setValues(initialValues);
    },
  };
}

export function SendAssets({
  values,
  setValues,
  accounts,
  balancesByAddresses,
  assets,
  assetsInfo,
  usdPrices,
  formError,
  onSubmit,
}: Props) {
  const { i18n } = useLingui();
  const network = useAppSelector(state => state.network);
  const networkConfig = WAVES_NETWORK_CONFIGS[network];

  const balances = useMemo(() => {
    const result: { [address: string]: Money[] | undefined } = {};

    for (const [address, addressBalances] of Object.entries(
      balancesByAddresses
    )) {
      if (!addressBalances) return;

      const balancesMoney: Money[] = [];

      for (const balance of addressBalances) {
        const asset = assets[balance.assetId];

        if (!asset) return;

        balancesMoney.push(Money.fromCoins(balance.balance, asset));
      }

      result[address] = balancesMoney;
    }

    return result;
  }, [assets, balancesByAddresses]);

  const balanceAssets = useMemo(() => {
    return Object.values(
      Object.values(balances ?? {})
        .flat()
        .filter(isNotNull)
        .reduce<Record<string, AssetDetails>>((items, next) => {
          items[next.assetInfo.assetId] = next.assetInfo;
          return items;
        }, {})
    )
      .flat()
      .sort((a, b) =>
        b.assetId === 'WAVES'
          ? 1
          : (assetsInfo[a.assetId]?.ticker ?? a.name).localeCompare(
              assetsInfo[b.assetId]?.ticker ?? b.name
            )
      );
  }, [balances, assetsInfo]);

  const {
    assetId,
    senderPublicKey,
    recipient,
    recipientAddress,
    recipientTouched,
    amount,
    amountTouched,
    feeAssetId,
  } = values;

  const asset = balanceAssets.find(a => a.assetId === assetId);

  const sender = accounts.find(
    account => account.publicKey === senderPublicKey
  );

  const address =
    sender &&
    encodeAddressFromPublicKey(sender.publicKey, networkConfig.chainId);

  function getAssetBalanceAmounts(
    selectedAddress: string | undefined,
    selectedAssetId: string
  ) {
    const balance =
      selectedAddress != null
        ? balances?.[selectedAddress]?.find(
            b => b.assetInfo.assetId === selectedAssetId
          )
        : undefined;

    const amountInTokens = balance?.getTokens() || new BigNumber(0);
    const priceInUsd = new BigNumber(
      (selectedAssetId && usdPrices[selectedAssetId]) || 0
    );
    const amountInUsd = amountInTokens.mul(priceInUsd);

    return { amountInTokens, amountInUsd, priceInUsd };
  }

  const {
    priceInUsd,
    amountInTokens: balanceInTokens,
    amountInUsd: balanceInUsd,
  } = getAssetBalanceAmounts(address, assetId);

  const amountBn = new BigNumber(amount.value || 0);

  const amountBnInUsd = amountBn.mul(priceInUsd);

  const [wavesFee, setWavesFee] = useState<string>();

  const debouncedRecipient = useDebouncedValue(recipient, 500);
  const [recipientLoading, setRecipientLoading] = useState(false);
  const [recipientError, setRecipientError] = useState('');

  useEffect(() => {
    if (isAddressString(debouncedRecipient)) {
      setValues(prev => ({ ...prev, recipientAddress: debouncedRecipient }));
      return;
    }

    if (
      ![
        WAVES_NETWORK_CONFIGS.mainnet.chainId,
        WAVES_NETWORK_CONFIGS.testnet.chainId,
      ].includes(networkConfig.chainId) ||
      !debouncedRecipient ||
      !debouncedRecipient.includes('.')
    ) {
      setValues(prev => ({ ...prev, recipientAddress: debouncedRecipient }));
      setRecipientError(t(i18n)`Invalid address`);
      return;
    }

    import(
      /* webpackChunkName: "domains-client" */
      '@waves-domains/client'
    ).then(({ WavesDomainsClient }) => {
      const domainsClient = new WavesDomainsClient({
        network:
          networkConfig.chainId === WAVES_NETWORK_CONFIGS.mainnet.chainId
            ? 'mainnet'
            : 'testnet',
      });

      setRecipientLoading(true);

      domainsClient
        .resolve(debouncedRecipient.toLowerCase())
        .then(resolvedAddress => {
          setValues(prev => ({
            ...prev,
            recipientAddress: resolvedAddress ?? '',
          }));
          if (resolvedAddress == null)
            setRecipientError(t(i18n)`Invalid domain`);
        })
        .finally(() => {
          setRecipientLoading(false);
        });
    });
  }, [networkConfig.chainId, debouncedRecipient, setValues, i18n]);

  useEffect(() => {
    if (!address) return;

    fetchScriptInfo(network, address)
      .then(({ extraFee }) =>
        setWavesFee(
          new BigNumber(DEFAULT_FEE_IN_WAVES).add(extraFee).toString()
        )
      )
      .catch(() => setWavesFee(DEFAULT_FEE_IN_WAVES));
  }, [networkConfig.chainId, address, network]);

  const feeOptions =
    address && wavesFee
      ? getFeeOptions({
          assets,
          balances: balancesByAddresses[address] ?? [],
          initialFee: Money.fromCoins(wavesFee, WAVES_ASSET_DETAILS),
          txType: TRANSACTION_TYPE.TRANSFER,
          usdPrices,
        })
      : undefined;

  const defaultFee =
    feeOptions?.[0]?.money ??
    (wavesFee ? Money.fromCoins(wavesFee, WAVES_ASSET_DETAILS) : undefined);

  const selectedFee =
    feeOptions?.find(f => f.money.assetInfo.assetId === feeAssetId)?.money ??
    defaultFee;

  const feeError =
    wavesFee && balances && (!feeOptions || feeOptions.length === 0)
      ? t(i18n)`Insufficient funds to pay the transaction fee`
      : null;

  const maxBalanceInTokens = balanceInTokens.sub(
    selectedFee && assetId === feeAssetId
      ? selectedFee.getTokens()
      : new BigNumber(0)
  );

  const amountError =
    amount && amountBn.lte(0)
      ? t(i18n)`Amount must be greater than 0`
      : asset && senderPublicKey && amountBn.gt(maxBalanceInTokens)
      ? t(i18n)`Insufficient funds`
      : undefined;

  return (
    <form
      className={styles.card}
      onSubmit={e => {
        e.preventDefault();

        if (!amount || !asset || !sender || !recipient || !selectedFee) return;

        const { name, publicKey, walletType } = sender;

        onSubmit({
          sender: { name, publicKey, walletType },
          amount: Money.fromTokens(amount.value, asset),
          recipient,
          fee: selectedFee,
        });
      }}
    >
      <FormControlProvider>
        <div>
          <FormLabel>{t(i18n)`Asset`}</FormLabel>

          {balances == null ? (
            <div className={styles.assetInput}>
              <Input placeholder={t(i18n)`Choose asset...`} disabled />
              <Spinner className={styles.assetSpinner} size={16} />
            </div>
          ) : balanceAssets.length === 0 ? (
            <Input
              placeholder={t(i18n)`You have no assets in your Wallet`}
              disabled
            />
          ) : (
            <Select
              value={assetId}
              placeholder={t(i18n)`Choose asset`}
              items={balanceAssets
                .map(a => ({
                  value: a.assetId,
                  label: assetsInfo[a.assetId]?.ticker || a.name,
                  logo: assetsInfo[a.assetId]?.url,
                  ...getAssetBalanceAmounts(address, a.assetId),
                }))
                .sort((a, b) =>
                  b.value === 'WAVES'
                    ? 1
                    : Number(b.amountInUsd.sub(a.amountInUsd))
                )}
              renderOptionLabel={({
                label,
                logo,
                amountInTokens,
                amountInUsd,
                value,
              }) => (
                <div className={styles.assetOptionLabel}>
                  <Logo
                    className={styles.assetIcon}
                    name={label}
                    logo={logo}
                    size={24}
                    objectId={value}
                  />

                  <span className={styles.assetLabel}>{label}</span>

                  <span className={styles.amountInTokensLabel}>
                    {address && amountInTokens.toFormat()}
                  </span>

                  <span className={styles.amountInUsdLabel}>
                    {address && `(${formatUsdPrice(amountInUsd)})`}
                  </span>
                </div>
              )}
              renderButtonLabel={({ label, logo, value }) => (
                <div className={styles.assetOptionLabel}>
                  <Logo name={label} logo={logo} size={24} objectId={value} />
                  {label}
                </div>
              )}
              onChange={value => {
                setValues(prev => ({ ...prev, assetId: value }));
              }}
            />
          )}

          <FormHelperText className={styles.assetHelperText}>
            {balances != null && balanceAssets.length === 0
              ? t(
                  i18n
                )`Token purchase coming soon, use wallet-to-wallet replenishment`
              : address &&
                assetId && (
                  <>
                    {formatUsdPrice(balanceInUsd)}
                    <span className={styles.walletBalanceLabel}>
                      <WalletIcon /> {balanceInTokens.toFormat()}
                    </span>
                  </>
                )}
          </FormHelperText>
        </div>
      </FormControlProvider>

      <FormControlProvider
        error={recipientTouched ? recipientError : undefined}
      >
        <div>
          <div className={styles.recipientLabel}>
            <FormLabel>{t(i18n)`Recipient`}</FormLabel>
            {!isAddressString(recipient) && recipientAddress && (
              <span>{ellipsis(recipientAddress, 5)}</span>
            )}
          </div>

          <div className={styles.recipientInput}>
            <Input
              placeholder={t(i18n)`Address or domain`}
              value={recipient}
              onChange={e => {
                setRecipientError('');
                setValues(prev => ({
                  ...prev,
                  recipient: e.target.value,
                  recipientAddress: '',
                }));
              }}
              onBlur={() =>
                setValues(prev => ({ ...prev, recipientTouched: true }))
              }
            />
            {recipientLoading && (
              <Spinner className={styles.recipientSpinner} size={16} />
            )}
          </div>

          {recipientTouched && recipientError && (
            <FormHelperText hasError>{recipientError}</FormHelperText>
          )}
        </div>
      </FormControlProvider>

      <FormControlProvider error={amountTouched ? amountError : undefined}>
        <div>
          <FormLabel>{t(i18n)`Amount`}</FormLabel>

          <NumericFormat
            allowLeadingZeros={false}
            value={amount.formattedValue}
            placeholder="0.0"
            customInput={Input}
            thousandSeparator
            decimalScale={asset?.decimals}
            onValueChange={({ value, formattedValue }) =>
              setValues(prev => ({
                ...prev,
                amount: { value, formattedValue },
              }))
            }
            onBlur={() => setValues(prev => ({ ...prev, amountTouched: true }))}
          />

          <FormHelperText
            hasError={Boolean(amountTouched && amountError)}
            className={styles.amountHelperText}
          >
            {amountTouched && amountError
              ? amountError
              : assetId && <span>{formatUsdPrice(amountBnInUsd)}</span>}
            {balanceInTokens.gt(0) && (
              <button
                type="button"
                className={styles.amountUseMaxButton}
                onClick={() => {
                  setValues(prev => ({
                    ...prev,
                    amount: {
                      value: maxBalanceInTokens.toString(),
                      formattedValue: maxBalanceInTokens.toFormat(),
                    },
                    amountTouched: true,
                  }));
                }}
              >{t(i18n)`Use max`}</button>
            )}
          </FormHelperText>
        </div>
      </FormControlProvider>

      {senderPublicKey && (
        <div>
          <Boundary className={styles.feeRow}>
            {t(i18n)`Transaction fee`}
            {wavesFee == null ? (
              <Spinner size={16} />
            ) : feeOptions && feeOptions.length > 1 ? (
              <Select
                variant="minimal"
                autoWidth
                value={selectedFee?.assetInfo.assetId}
                placement="bottom-end"
                items={feeOptions
                  .map(f => {
                    const feeAsset = f.money.assetInfo;
                    return {
                      value: feeAsset.assetId,
                      label:
                        assetsInfo[feeAsset.assetId]?.ticker || feeAsset.name,
                      logo: assetsInfo[feeAsset.assetId]?.url,
                      feeAmount: f.money,
                      feeAmountInUsd: f.money
                        .getTokens()
                        .mul(usdPrices[feeAsset.assetId] ?? 0),
                    };
                  })
                  .sort((a, b) =>
                    b.value === 'WAVES'
                      ? 1
                      : Number(a.feeAmountInUsd.sub(b.feeAmountInUsd))
                  )}
                renderOptionLabel={({
                  value,
                  label,
                  logo,
                  feeAmount,
                  feeAmountInUsd,
                }) => (
                  <div className={styles.feeOptionLabel}>
                    <Logo
                      className={styles.assetIcon}
                      name={label}
                      logo={logo}
                      size={24}
                      objectId={value}
                    />

                    <span className={styles.assetLabel}>{label}</span>

                    <span className={styles.amountInTokensLabel}>
                      {address && feeAmount.getTokens().toFormat()}
                    </span>

                    <span className={styles.amountInUsdLabel}>
                      {address && `(${formatUsdPrice(feeAmountInUsd)})`}
                    </span>
                  </div>
                )}
                renderButtonLabel={({ label, feeAmount }) => (
                  <div className={styles.feeButtonLabel}>
                    {feeAmount.getTokens().toFormat()} {label}
                  </div>
                )}
                onChange={value =>
                  setValues(prev => ({ ...prev, feeAssetId: value }))
                }
              />
            ) : (
              <span>
                {Money.fromCoins(wavesFee, WAVES_ASSET_DETAILS)
                  .getTokens()
                  .toFormat()}{' '}
                {WAVES_ASSET_DETAILS.name}
              </span>
            )}
          </Boundary>

          {feeError && <FormHelperText hasError>{feeError}</FormHelperText>}
        </div>
      )}

      <Button
        type="submit"
        disabled={
          !assetId ||
          !senderPublicKey ||
          !recipientAddress ||
          !amount ||
          !wavesFee ||
          Boolean(recipientError) ||
          Boolean(amountError) ||
          Boolean(feeError)
        }
      >
        Continue
      </Button>

      {formError && <p className={styles.formError}>{formError}</p>}
    </form>
  );
}

function isAddressString(input: string, chainId?: number) {
  try {
    return verifyAddress(base58Decode(input), { chainId });
  } catch (err) {
    return false;
  }
}
