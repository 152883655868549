import clsx from 'clsx';
import invariant from 'tiny-invariant';

import { useFormControlContext } from './formControl';
import * as styles from './input.module.css';

type Props = Pick<
  React.ComponentProps<'input'>,
  | 'value'
  | 'defaultValue'
  | 'onChange'
  | 'placeholder'
  | 'onBlur'
  | 'className'
  | 'name'
  | 'maxLength'
  | 'disabled'
> & {
  hasError?: boolean;
};
export function Input({ className, ...props }: Props) {
  const formControlContext = useFormControlContext();

  let hasError: boolean;
  if (formControlContext) {
    invariant(
      props.hasError === undefined,
      '`hasError` prop is passed to an `Input` component wrapped to `FormControl`.\n Use `error` prop on `FormControl` instead'
    );
    hasError = Boolean(formControlContext.error);
  } else {
    hasError = props.hasError === true;
  }

  return (
    <input
      {...props}
      id={formControlContext?.id}
      className={clsx(className, styles.input, {
        [styles.error]: hasError,
      })}
    />
  );
}
