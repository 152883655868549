import clsx from 'clsx';

import * as styles from './spinner.module.css';

interface Props {
  className?: string;
  size: number;
}

export function Spinner({ className, size }: Props) {
  const center = size / 2;
  const strokeWidth = size / 16 + 1;
  const radius = size / 2 - strokeWidth / 2;
  const pathLength = 2 * Math.PI * radius;

  return (
    <svg
      aria-label="Loading..."
      className={clsx(className, styles.root)}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <circle
        className={styles.circle}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        style={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ['--path-length' as any]: `${pathLength}px`,
        }}
      />
    </svg>
  );
}
