import clsx from 'clsx';
import { useState } from 'react';

import { Footer } from './footer';
import { Header } from './header';
import * as styles from './layout.module.css';
import { MobileMenu } from './mobileMenu';
import { Sidebar } from './sidebar';

export function Container({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={clsx(className, styles.container)}>{children}</div>;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className={styles.root}>
      <Header
        className={styles.header}
        openMobileMenu={() => setIsMobileMenuOpen(true)}
      />
      <Sidebar className={styles.sidebar} />
      <main className={styles.main}>{children}</main>
      <Footer className={styles.footer} />
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
      />
    </div>
  );
}
