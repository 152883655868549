// extracted by mini-css-extract-plugin
export var balanceAsset = "vUDh1aDQVLAMTqgWfzSL";
export var card = "mhMTtDrdSXy6L25cYF8m";
export var cardSkeleton = "K2BsToKzwzITtGAvCr7l";
export var mobileVendorProductGroups = "WXKb0OoX3XjSC3rZnLmh";
export var productBadge = "tXSYNyai5Cbqtc2oSfZY";
export var productBadgelSkeleton = "OEW8nt8ReNt8Bf1MHTTW";
export var productSkeleton = "WrkWkRjLmJjKwtpQGIZ6";
export var vendorAnchor = "PRhD_9fEowMSisUP5eNo";
export var vendorGroupAvatar = "hB8ZF4EeUWIFYLSzRsx0";
export var vendorGroupHeader = "PvPppd67HPPlg3HdlkEA";
export var vendorGroupHeaderSkeleton = "Oqg9L53H86B9ugy1kJEv";
export var vendorGroupList = "jfAisV0Bw8vjN0giJtwe";
export var vendorGroupName = "CWAPmgEvCZkLvFRMOxGi";
export var vendorGroupNameSkeleton = "HvDEW960Qahc7hI2YFEM";
export var vendorGroupTotal = "uXc2Vbh96m2IAFfTu9oa";
export var vendorGroupTotalSkeleton = "MYMVjDXx9VvPoxVxVLlM";
export var vendorProductGroups = "IcX6F1TNmbSgYdd6B2a8";