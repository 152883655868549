import { type AllLocaleData, type I18n, setupI18n } from '@lingui/core';
import { en } from 'make-plural/plurals';

const localeData: AllLocaleData = {
  en: { plurals: en },
};

export const SUPPORTED_LOCALES = new Set(Object.keys(localeData));
const FALLBACK_LOCALE = 'en';

export function parseAcceptLanguageHeader(header: string | null) {
  return (header ?? '')
    .split(',')
    .map((entry): [string, number] => {
      const [locale, q = '1'] = entry.split(';q=');

      return [locale.trim(), Number(q)];
    })
    .sort((a, b) => b[1] - a[1])
    .map(([locale]) => locale)
    .filter(locale => locale && locale !== '*');
}

export function getPreferredLocale(locales: readonly string[]) {
  return (
    locales.map(l => l.split('-')[0]).find(l => SUPPORTED_LOCALES.has(l)) ??
    FALLBACK_LOCALE
  );
}

export function setupLocales() {
  const i18n = setupI18n();
  i18n.loadLocaleData(localeData);
  return i18n;
}

export async function activateLocale(i18n: I18n, locale: string) {
  const { messages } = await import(`./locale/${locale}/messages`);

  i18n.load(locale, messages);
  i18n.activate(locale);
}
