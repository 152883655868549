import clsx from 'clsx';
import ColorHash from 'color-hash';
import { useState } from 'react';

import * as styles from './logo.module.css';

interface Props {
  logo?: string | null;
  name: string;
  objectId?: string;
  size?: number | string;
  className?: string;
  alt?: string;
}

export function Logo({
  logo,
  name,
  size = '1em',
  className,
  alt = '',
  objectId,
}: Props) {
  const [hasError, setHasError] = useState(false);

  const sizeValue = typeof size === 'number' ? `${size}px` : size;

  const hasLogo = logo != null && !hasError;

  const style = {
    width: sizeValue,
    height: sizeValue,
    backgroundColor:
      objectId && !hasLogo ? new ColorHash().hex(objectId) : undefined,
  };

  return (
    <div className={clsx(styles.logoContainer, className)} style={style}>
      {hasLogo ? (
        <img
          className={styles.logoImage}
          src={logo}
          alt={alt}
          onError={() => setHasError(true)}
        />
      ) : (
        <span style={{ fontSize: `calc(${sizeValue} / 2)` }}>
          {name.charAt(0)}
        </span>
      )}
    </div>
  );
}
