// extracted by mini-css-extract-plugin
export var clickable = "tZqiS57gAMQUo2SXN0cr";
export var dApp = "h8Vu6XLOobAawd6MUxvQ";
export var dAppContent = "icW3PcuYjxIzqm35sqtN";
export var dAppLogo = "zEjUoOjhy84Rx1blWI1y";
export var dAppLogoBadge = "fgO6MTNL_6NNlXM6N3yh";
export var dAppTitle = "u9jYWYiuQ1PbSidYRBhQ";
export var dAppValue = "aa9_WqVHrQjD8rYg9oWI";
export var dApp_active = "byR3PcpLnBkY59jXrHP9";
export var dApps = "kzQh_WuAAAPBxVnoIwW2";
export var dAppsGrid = "LklqNp7TQqHk134UJxtI";
export var itemsSkeleton = "lCSKHvB0kDBbPBvQqInC";
export var otherDApps = "dc_ZxXghKgd3S_lRJFwP";
export var skeleton = "myeZKbhweO8ChRmK1Zu1";
export var stack = "quHD3GAEQAbkw4SqsIxo";
export var stackItem = "xNXSqjz2_kOUBFJEeprk";
export var stackItemFill = "wU_4dtKdjzxdqLsboRou";
export var stackItem_active = "PJAFjGIO05MJ9lYUothx";
export var stackSkeleton = "WOERWtVoLaZma70p5NNi";