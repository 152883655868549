import { createContext, useContext, useMemo } from 'react';
import invariant from 'tiny-invariant';

import { useAppSelector } from '../store/react';
import { AddAccount } from './addAccount/addAccount';
import { type PrivateAccountData } from './types';

const AccountsContext = createContext<PrivateAccountData[] | null>(null);

export function RequireAccounts({ children }: { children: React.ReactNode }) {
  const accounts = useAppSelector(state => state.accounts);

  if (accounts == null) {
    return null;
  }

  return (
    <AccountsContext.Provider value={accounts}>
      {accounts.length > 0 ? children : <AddAccount />}
    </AccountsContext.Provider>
  );
}

export function useAccounts({
  onlySelected = false,
}: { onlySelected?: boolean } = {}) {
  const accounts = useContext(AccountsContext);

  const selectedAccountPublicKey = useAppSelector(
    state => state.selectedAccountPublicKey
  );

  invariant(
    accounts,
    'No context provided: `useAccounts()` can only be used in a descendant of `<RequireAccounts>`.'
  );

  return useMemo(() => {
    if (onlySelected && selectedAccountPublicKey) {
      return accounts.filter(
        account => account.publicKey === selectedAccountPublicKey
      );
    }

    return accounts;
  }, [accounts, onlySelected, selectedAccountPublicKey]);
}
