// extracted by mini-css-extract-plugin
export var content = "Vem7RFzOxHC8dlQgH_qk";
export var contentContainer = "Gdxl12u51y6Yi0T0nb6I";
export var footerMenu = "Og3IjyJuFU50h2USOej_";
export var footerMenu_container = "ubDdaYOp8iLsK6Mx1OcO";
export var footerMenu_item = "AYVzFtieV9aXsDwPJiH9";
export var footerMenu_list = "swZ6PYtnlsMBB0hfOsXT";
export var footerMenu_title = "tXr3ZBq98cTAdF8BzwNw";
export var logo = "BBkVqADVQv8CVZuA9dgL";
export var logoContainer = "V_bS78w23CG0hONupmfd";
export var root = "fuPs449HFBX4lrcb7tDy";