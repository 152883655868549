import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';

import { useDataServiceAssets } from '../../cache/hooks';
import { PortfolioWallet } from '../../portfolio/wallet';
import { useAppSelector } from '../../store/react';
import { WAVES_ASSET_DETAILS } from '../../waves/redux';
import { usePortfolioPageContext } from './portfolio';
import * as styles from './portfolio.module.css';

export function WalletPage() {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);

  const dataServiceAssets = useDataServiceAssets({ network });

  const { balances, usdPrices, walletWorth, investments } =
    usePortfolioPageContext();

  const productAmountsByAssetId = investments?.reduce<
    Record<string, BigNumber>
  >((acc, { amounts }) => {
    for (const amount of amounts) {
      const key = amount.assetInfo.assetId;
      acc[key] ??= new BigNumber(0);
      acc[key] = acc[key].add(amount.getTokens());
    }

    return acc;
  }, {});

  if (balances?.length === 0) {
    return (
      <div className={styles.emptyMessage}>{t(i18n)`Your Wallet is empty`}</div>
    );
  }

  const assetBalances = balances
    ?.map(balance => {
      const hasPrice = new BigNumber(
        usdPrices[balance.assetInfo.assetId] ?? '0'
      ).gt(0);

      const productsAssetAmount =
        productAmountsByAssetId?.[balance.assetInfo.assetId];

      const dataServiceAsset = dataServiceAssets[balance.assetInfo.assetId];

      return {
        id: balance.assetInfo.assetId,
        ticker: dataServiceAsset?.ticker || balance.assetInfo.name,
        logo: dataServiceAsset?.url,
        price: hasPrice
          ? new BigNumber(usdPrices[balance.assetInfo.assetId] ?? '0')
          : undefined,
        priceChange: undefined,
        available: balance.getTokens(),
        worth: hasPrice
          ? balance.getTokens().mul(usdPrices[balance.assetInfo.assetId] ?? '0')
          : undefined,
        total: productsAssetAmount
          ? balance.getTokens().add(productsAssetAmount)
          : undefined,
      };
    })
    .sort((a, b) => {
      const aWorth = new BigNumber(a.worth ?? 0);
      const bWorth = new BigNumber(b.worth ?? 0);

      if (a.id === WAVES_ASSET_DETAILS.assetId) {
        return -1;
      }

      if (aWorth.gt(bWorth)) {
        return -1;
      }

      if (aWorth.lt(bWorth)) {
        return 1;
      }

      return a.ticker.localeCompare(b.ticker);
    });

  return <PortfolioWallet assetBalances={assetBalances} worth={walletWorth} />;
}
