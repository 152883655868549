import {
  base58Decode,
  base58Encode,
  createAddress,
} from '@keeper-wallet/waves-crypto';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button } from '../_core/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogContextConsumer,
  DialogHeader,
} from '../_core/dialog';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Logo } from '../_core/logo';
import type { Money } from '../_core/money';
import { Spinner } from '../_core/spinner';
import type { PrivateAccountData } from '../accounts/types';
import type {
  DataServiceAssets,
  DataServiceUsdPrices,
} from '../dataService/redux';
import { CheckIcon } from '../icons/checkIcon';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import * as styles from './sendStatusDialog.module.css';

export interface SendStatusDialogProps {
  isOpen?: boolean;
  onIsOpenChange?: (open: boolean) => void;
  assetsInfo: DataServiceAssets;
  usdPrices: DataServiceUsdPrices;
  transferData: {
    txId?: string;
    sender: PrivateAccountData;
    recipient: string;
    amount: Money;
    fee: Money;
  };
  transferState?:
    | { type: 'pending' }
    | { type: 'applicationFailed' }
    | { type: 'transactionLost' }
    | { type: 'applicationSuccess' }
    | { type: 'broadcastError'; message: string };
}

export function SendStatusDialog({
  isOpen,
  onIsOpenChange,
  assetsInfo,
  usdPrices,
  transferData: {
    txId,
    sender: { publicKey, name },
    amount,
    recipient,
    fee,
  },
  transferState = { type: 'pending' },
}: SendStatusDialogProps) {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const networkConfig = WAVES_NETWORK_CONFIGS[network];
  const chainId = networkConfig.chainId;

  const address = base58Encode(createAddress(base58Decode(publicKey), chainId));

  const amountFormatted = amount.getTokens().toFormat();
  const amountAsset = assetsInfo[amount.assetInfo.assetId];
  const amountTicker = amountAsset?.ticker || amount.assetInfo.name;

  const feeFormatted = fee.getTokens().toFormat();
  const feeTicker =
    assetsInfo[fee.assetInfo.assetId]?.ticker || fee.assetInfo.name;

  return (
    <Dialog isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
      <DialogContent className={styles.root}>
        <DialogHeader>
          <DialogClose />

          <img
            src={(transferState.type === 'pending'
              ? new URL('./statusWaiting.webp', import.meta.url)
              : transferState.type === 'applicationSuccess'
              ? new URL('./statusSucceed.webp', import.meta.url)
              : transferState.type === 'applicationFailed' ||
                transferState.type === 'broadcastError'
              ? new URL('./statusFailed.webp', import.meta.url)
              : new URL('./statusUnknown.webp', import.meta.url)
            ).toString()}
          />
        </DialogHeader>

        <h3 className={styles.statusMessage}>
          {transferState.type === 'pending'
            ? t(i18n)`Waiting for Send...`
            : transferState.type === 'applicationSuccess'
            ? t(i18n)`Send succeeded`
            : transferState.type === 'applicationFailed' ||
              transferState.type === 'broadcastError'
            ? t(i18n)`Send failed`
            : t(i18n)`Send lost`}
        </h3>

        <div className={styles.plate}>
          <p className={styles.accountRow}>
            {name}
            <span className={styles.accountHelperText}>{address}</span>
          </p>

          {transferState.type === 'pending' ||
          transferState.type === 'applicationSuccess' ? (
            <div className={styles.statusCard}>
              <div className={styles.statusRow}>
                <div className={styles.assetItem}>
                  <Logo
                    className={styles.assetIcon}
                    logo={amountAsset?.url}
                    name={amountAsset?.ticker || amount.assetInfo.name}
                    size={24}
                    objectId={amountAsset?.id}
                  />
                  {amountAsset?.ticker || amount.assetInfo.name}
                </div>
                {transferState.type === 'pending' ? (
                  <Spinner size={24} />
                ) : (
                  <CheckIcon className={styles.statusIcon} />
                )}
              </div>
              <div className={styles.statusRow}>
                <span className={styles.assetAmountInTokens}>
                  –{amount.getTokens().toFormat()}
                </span>

                {usdPrices[amount.assetInfo.assetId] && (
                  <span className={styles.assetAmountInUsd}>
                    {formatUsdPrice(
                      amount
                        .getTokens()
                        .mul(usdPrices[amount.assetInfo.assetId] ?? '0')
                    )}
                  </span>
                )}
              </div>
              <div className={styles.recipientRow}>
                <span className={styles.recipientLabel}>
                  {t(i18n)({ comment: 'Recipient', message: 'to' })}:
                </span>{' '}
                {recipient}
              </div>
            </div>
          ) : transferState.type === 'applicationFailed' ||
            transferState.type === 'broadcastError' ? (
            <>
              {transferState.type === 'broadcastError' && (
                <div className={styles.statusCard}>
                  <p className={styles.statusErrorMessage}>
                    {transferState.message}
                  </p>
                </div>
              )}

              <div className={styles.statusBox}>
                <p>
                  {t(i18n)({
                    message:
                      'Your assets ({amountFormatted} {amountTicker}) has been returned to your balance',
                    values: { amountFormatted, amountTicker },
                  })}
                </p>

                {transferState.type === 'applicationFailed' && (
                  <p>
                    {t(i18n)({
                      message: 'Deducted fee is -{feeFormatted} {feeTicker}',
                      values: { feeFormatted, feeTicker },
                    })}
                  </p>
                )}
              </div>
            </>
          ) : (
            <div className={styles.statusBox}>
              <p>{t(i18n)`We couldn’t find your transaction in blockchain`}</p>
            </div>
          )}
        </div>

        <div className={styles.transactionInfo}>
          {(transferState.type === 'applicationSuccess' ||
            transferState.type === 'applicationFailed' ||
            transferState.type === 'transactionLost') &&
            txId && (
              <a
                className={styles.explorerUrl}
                target="_blank"
                href={`https://wavesexplorer.com/transactions/${txId}?network=${network}`}
                rel="noopener"
              >
                {transferState.type === 'transactionLost'
                  ? t(i18n)`Try to find transaction in Explorer`
                  : t(i18n)`View in Explorer`}
              </a>
            )}
        </div>

        <DialogContextConsumer>
          {({ setOpen }) => (
            <Button variant="outlined" onClick={() => setOpen(false)}>
              {t(i18n)`Close`}
            </Button>
          )}
        </DialogContextConsumer>
      </DialogContent>
    </Dialog>
  );
}
