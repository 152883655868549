import BigNumber from '@waves/bignumber';
import { TRANSACTION_TYPE, type TransactionType } from '@waves/ts-types';
import invariant from 'tiny-invariant';

import type { DataServiceUsdPrices } from '../dataService/redux';
import {
  type AssetDetails,
  type AssetDetailsRecord,
  WAVES_ASSET_DETAILS,
  type WavesAssetBalanceResponse,
} from '../waves/redux';
import { Money } from './money';

function convertFeeToAsset(fee: Money, asset: AssetDetails) {
  const minSponsoredFeeFrom = fee.assetInfo.minSponsoredAssetFee;
  const minSponsoredFeeTo = asset.minSponsoredAssetFee;

  invariant(minSponsoredFeeFrom && minSponsoredFeeTo);

  return Money.fromCoins(
    fee
      .getCoins()
      .mul(minSponsoredFeeTo)
      .div(minSponsoredFeeFrom)
      .roundTo(0, 0),
    asset
  );
}

interface FeeOption {
  assetBalance: WavesAssetBalanceResponse;
  money: Money;
}

export function getFeeOptions({
  assets,
  balances,
  initialFee,
  txType,
  usdPrices,
}: {
  assets: AssetDetailsRecord;
  balances: WavesAssetBalanceResponse[];
  initialFee: Money;
  txType: TransactionType;
  usdPrices: DataServiceUsdPrices;
}) {
  if (
    txType !== TRANSACTION_TYPE.TRANSFER &&
    txType !== TRANSACTION_TYPE.INVOKE_SCRIPT
  ) {
    return [];
  }

  const feeInWaves = convertFeeToAsset(initialFee, WAVES_ASSET_DETAILS);

  return balances
    .map(assetBalance => ({
      asset: assets[assetBalance.assetId],
      assetBalance,
    }))
    .filter(
      (
        item
      ): item is {
        asset: AssetDetails;
        assetBalance: WavesAssetBalanceResponse;
      } => item.assetBalance.minSponsoredAssetFee != null && item.asset != null
    )
    .map(
      ({ asset, assetBalance }): FeeOption => ({
        assetBalance,
        money: convertFeeToAsset(initialFee, asset),
      })
    )
    .filter(
      ({ assetBalance, money }) =>
        new BigNumber(assetBalance.sponsorBalance ?? 0).gte(
          feeInWaves.getCoins()
        ) && new BigNumber(assetBalance.balance).gte(money.getCoins())
    )
    .sort((a, b) => {
      const aUsdSum = a.money
        .getTokens()
        .mul(usdPrices[a.money.assetInfo.assetId] || '0');

      const bUsdSum = b.money
        .getTokens()
        .mul(usdPrices[b.money.assetInfo.assetId] || '0');

      if (aUsdSum.gt(bUsdSum)) {
        return 1;
      }

      if (aUsdSum.lt(bUsdSum)) {
        return -1;
      }

      const aSponsorBalance = new BigNumber(a.assetBalance.sponsorBalance ?? 0);
      const bSponsorBalance = new BigNumber(b.assetBalance.sponsorBalance ?? 0);

      if (aSponsorBalance.gt(bSponsorBalance)) {
        return -1;
      }

      if (aSponsorBalance.lt(bSponsorBalance)) {
        return 1;
      }

      return 0;
    });
}
