import { combineReducers } from 'redux';

import {
  accountsReducer,
  selectedAccountPublicKeyReducer,
} from '../accounts/redux';
import dataService from '../dataService/redux';
import network from '../network/redux';
import waves from '../waves/redux';

const cache = combineReducers({
  dataService,
  waves,
});

export const reducer = combineReducers({
  accounts: accountsReducer,
  cache,
  network,
  selectedAccountPublicKey: selectedAccountPublicKeyReducer,
});
