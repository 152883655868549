import { type Rect } from '@floating-ui/react';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const BoundaryContext = createContext<{
  rect: Rect | null;
} | null>(null);

export function useBoundaryContext() {
  return useContext(BoundaryContext);
}

export function Boundary({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [rect, setRect] = useState<Rect | null>(null);

  useEffect(() => {
    if (ref.current == null) return;

    const { x, width } = ref.current.getBoundingClientRect();

    setRect({
      x,
      width,
      y: 0,
      height: ref.current.ownerDocument.body.clientHeight,
    });
  }, [ref]);

  const contextValue = useMemo(() => ({ rect }), [rect]);

  return (
    <BoundaryContext.Provider value={contextValue}>
      <div ref={ref} style={style} className={className}>
        {children}
      </div>
    </BoundaryContext.Provider>
  );
}
