// extracted by mini-css-extract-plugin
export var amountHelperText = "_B2Y3MbZm0ieNeuQdtEP";
export var amountInTokensLabel = "L_TLhfGWtDkPYc40JumA";
export var amountInUsdLabel = "CPMy1JleO2qrFBMihANJ";
export var amountUseMaxButton = "d1hvr3URg1KCdkEDe6wJ";
export var assetHelperText = "MU342FjhyJK7wyKjLgpD";
export var assetIcon = "jfQMHa81latAeV8lq4Yr";
export var assetInput = "jZmr86xgPbnMBYSPrem7";
export var assetLabel = "qU668x4hZ1HQYFPeg16l";
export var assetOptionLabel = "_NA2FvyRzGChaEEV2twp";
export var assetSpinner = "OrVHzGDYrjfK6szn7mBV";
export var card = "szaNoK7496J0uy4_s_1Q";
export var feeButtonLabel = "LfByArrSZMi8qAzdNaze";
export var feeOptionLabel = "rcEULImpVWh0vTGoM1xX";
export var feeRow = "uMLxeIhA9_hIXlVPhY00";
export var formError = "rTBxfqU5NqvKVbko0miN";
export var recipientInput = "deq93ykYASrhfQ5nAD3Q";
export var recipientLabel = "JoC8gJFdgWWDszAWoDmt";
export var recipientSpinner = "t74ryEyKw9zeT3TZIhha";
export var senderBalanceInTokensLabel = "YqdYwer1ors_W0czOe3A";
export var senderBalanceInUsdLabel = "tAks6VEBMf6RYn99hzBp";
export var senderLabel = "E4L48q2AZzmLXLC1Y4NA";
export var senderOptionAddress = "JwQaMyndGb51BaPHS12W";
export var senderOptionLabel = "uGpvEoGCfb8vhg79pTmc";
export var walletBalanceLabel = "V1MxJyC45aY2XDDI_rmq";