import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  type Placement,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { useRef, useState } from 'react';

import { CheckIcon } from '../icons/checkIcon';
import { ChevronDownIcon } from '../icons/chevronDown';
import { useBoundaryContext } from './boundary';
import * as styles from './select.module.css';

interface SelectOption {
  value: string;
  label: string;
}

const VARIANT_CLASSES = {
  default: styles.select_variant_default,
  defaultSmall: styles.select_variant_defaultSmall,
  minimal: styles.select_variant_minimal,
};

// Based on Select implementaion https://floating-ui.com/docs/useListNavigation#examples
export function Select<T extends SelectOption>({
  variant = 'default',
  autoWidth,
  placeholder,
  placement = 'bottom-start',
  items,
  value,
  renderOptionLabel = (item: T) => item.label,
  renderButtonLabel = renderOptionLabel,
  renderPlaceholder = (text: string) => text,
  onChange,
}: {
  variant?: keyof typeof VARIANT_CLASSES;
  autoWidth?: boolean;
  placeholder?: string;
  placement?: Placement;
  value?: string;
  items: T[];
  renderOptionLabel?: (item: T) => React.ReactElement | string | number;
  renderButtonLabel?: (item: T) => React.ReactElement | string | number;
  renderPlaceholder?: (text: string) => React.ReactElement | string | number;
  onChange?: (value: string) => void;
}) {
  const { i18n } = useLingui();
  const boundaryContext = useBoundaryContext();

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const controllableIndex =
    value != null ? items.findIndex(i => i.value === value) : selectedIndex;

  function setControllableIndex(index: number) {
    setSelectedIndex(index);
    onChange?.(items[index].value);
  }

  const { x, y, strategy, refs, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight, availableWidth }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });
          if (boundaryContext) {
            elements.floating.style.maxWidth = `${availableWidth}px`;
          } else {
            elements.floating.style.width = `${rects.reference.width}px`;
          }
        },
        boundary: boundaryContext?.rect ?? 'clippingAncestors',
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef(items.map(item => item.label));
  const isTypingRef = useRef(false);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex: controllableIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex: controllableIndex,
    onMatch: isOpen ? setActiveIndex : setControllableIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, dismiss, role, listNav, typeahead]
  );

  const handleSelect = (index: number) => {
    setIsOpen(false);
    setControllableIndex(index);
  };

  const selectedItem =
    controllableIndex !== -1 ? items[controllableIndex] : undefined;

  return (
    <>
      <button
        type="button"
        tabIndex={0}
        ref={refs.setReference}
        className={clsx(styles.select, VARIANT_CLASSES[variant], {
          [styles.select_autoWidth]: autoWidth,
          [styles.placeholder]: selectedItem == null,
        })}
        {...getReferenceProps()}
      >
        {selectedItem
          ? renderButtonLabel(selectedItem)
          : renderPlaceholder(placeholder || t(i18n)`Select...`)}
        <ChevronDownIcon className={styles.buttonIcon} />
      </button>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className={styles.floating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              {...getFloatingProps()}
            >
              {items.map((item, i) => (
                <div
                  key={item.value}
                  ref={node => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === controllableIndex && i === activeIndex}
                  className={clsx(styles.option, {
                    [styles.optionActive]: i === activeIndex,
                  })}
                  {...getItemProps({
                    onClick() {
                      handleSelect(i);
                    },
                    onKeyDown(event) {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSelect(i);
                      }

                      if (event.key === ' ' && !isTypingRef.current) {
                        event.preventDefault();
                        handleSelect(i);
                      }
                    },
                  })}
                >
                  {renderOptionLabel(item)}
                  {i === controllableIndex && (
                    <CheckIcon className={styles.optionSelectedIcon} />
                  )}
                </div>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}
