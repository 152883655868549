import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';

import { Skeleton } from '../_core/skeleton';
import * as styles from './topNfts.module.css';
import { DashboardWidget } from './widget';

function NftCard({
  id,
  issuerLabel,
  label,
  src,
}: {
  id: string;
  issuerLabel: string;
  label: string;
  src?: string;
}) {
  return (
    <Link to={`/nfts/${id}`}>
      <article>
        <figure>
          <img
            className={styles.cardImage}
            src={
              src ??
              new URL('../nfts/nftPlaceholder.svg', import.meta.url).pathname
            }
            alt=""
            width={150}
            height={150}
          />

          <figcaption className={styles.cardCaption}>
            <span className={styles.cardLabel}>{label}</span>
            <cite className={styles.cardIssuerLabel}>{issuerLabel}</cite>
          </figcaption>
        </figure>
      </article>
    </Link>
  );
}

interface Props {
  className?: string;
  items:
    | Array<{
        id: string;
        issuerLabel: string;
        label: string;
        src?: string;
      }>
    | undefined;
}

export function TopNfts({ className, items }: Props) {
  const { i18n } = useLingui();

  return (
    <DashboardWidget
      className={className}
      heading={t(i18n)`NFTs`}
      headerLink={
        items == null
          ? undefined
          : {
              label: t(i18n)`See all`,
              to: '/portfolio/nfts',
            }
      }
    >
      <div className={styles.cards}>
        {items == null ? (
          <>
            {new Array(3).fill(undefined).map((_, index) => (
              <div key={index} className={styles.skeletonItem}>
                <Skeleton className={styles.imageSkeleton} />
                <Skeleton className={styles.labelSkeleton} />
                <Skeleton className={styles.issuerSkeleton} />
              </div>
            ))}
          </>
        ) : (
          items.map(item => (
            <NftCard
              key={item.id}
              id={item.id}
              issuerLabel={item.issuerLabel}
              label={item.label}
              src={item.src}
            />
          ))
        )}
      </div>
    </DashboardWidget>
  );
}
