import { type Provider } from '@waves/signer';

import { WalletType } from './types';

export async function createWalletProvider(
  origin: string,
  provider: WalletType
): Promise<Provider | null> {
  switch (provider) {
    case WalletType.KeeperExtension: {
      const { ProviderKeeper } = await import(
        /* webpackChunkName: "provider-keeper" */
        '@waves/provider-keeper'
      );

      return new ProviderKeeper();
    }
    case WalletType.KeeperMobile: {
      const { ProviderKeeperMobile } = await import(
        /* webpackChunkName: "provider-keeper-mobile" */
        '@keeper-wallet/provider-keeper-mobile'
      );

      return new ProviderKeeperMobile({
        name: 'Keeper Web',
        icon: new URL('/icon-512.png', origin).href,
      });
    }
    case WalletType.Debug:
    default:
      return null;
  }
}
