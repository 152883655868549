import { createContext, useContext, useId, useMemo } from 'react';

import { FormHelperText } from './formHelperText';
import { FormLabel } from './formLabel';

const FormControlContext = createContext<{
  error?: string;
  id: string;
} | null>(null);

export function useFormControlContext() {
  return useContext(FormControlContext);
}

interface Props {
  children: React.ReactNode;
  label?: string;
  helperText?: string;
  error?: string;
  className?: string;
  id?: string;
}

export function FormControlProvider({
  children,
  error,
  id: idProp,
}: {
  children: React.ReactNode;
  error?: string;
  id?: string;
}) {
  const id = useId();
  const contextValue = useMemo(
    () => ({ error, id: idProp ?? id }),
    [error, idProp, id]
  );

  return (
    <FormControlContext.Provider value={contextValue}>
      {children}
    </FormControlContext.Provider>
  );
}

export function FormControl({
  children,
  label,
  helperText,
  error,
  className,
  id,
}: Props) {
  return (
    <FormControlProvider id={id} error={error}>
      <div className={className}>
        {label && <FormLabel>{label}</FormLabel>}

        {children}

        {error ? (
          <FormHelperText hasError>{error}</FormHelperText>
        ) : (
          <FormHelperText>{helperText}</FormHelperText>
        )}
      </div>
    </FormControlProvider>
  );
}
