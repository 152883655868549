import invariant from 'tiny-invariant';

import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { type AppThunkAction } from '../store/types';
import { type PrivateAccountData, WalletType } from './types';
import { createWalletProvider } from './utils';

export function accountsReducer(
  state: PrivateAccountData[] | null = null,
  action:
    | { type: 'SET_ACCOUNTS'; payload: PrivateAccountData[] }
    | { type: 'ADD_ACCOUNT'; payload: PrivateAccountData }
    | {
        type: 'EDIT_ACCOUNT';
        payload: Partial<Omit<PrivateAccountData, 'publicKey'>> &
          Pick<PrivateAccountData, 'publicKey'>;
      }
    | { type: 'DELETE_ACCOUNT'; payload: { publicKey: string } }
): PrivateAccountData[] | null {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return action.payload;
    case 'ADD_ACCOUNT':
      return state && state.concat(action.payload);
    case 'EDIT_ACCOUNT':
      return (
        state &&
        state.map(account => {
          if (account.publicKey === action.payload.publicKey) {
            return {
              ...account,
              ...action.payload,
            };
          }

          return account;
        })
      );
    case 'DELETE_ACCOUNT':
      return (
        state &&
        state.filter(account => account.publicKey !== action.payload.publicKey)
      );
    default:
      return state;
  }
}

export function readAccountsFromDB(): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { useMainDb }) => {
    try {
      const accounts = await useMainDb(db =>
        db.transaction('accounts').objectStore('accounts').getAll()
      );

      dispatch({
        type: 'SET_ACCOUNTS',
        payload: accounts,
      });
    } catch (err) {
      if (
        err &&
        typeof err === 'object' &&
        'name' in err &&
        err.name === 'InvalidStateError'
      ) {
        dispatch({
          type: 'SET_ACCOUNTS',
          payload: [],
        });
        return;
      }

      throw err;
    }
  };
}

export function addAccount(
  account: PrivateAccountData
): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { useMainDb }) => {
    await useMainDb(db =>
      db
        .transaction('accounts', 'readwrite', { durability: 'strict' })
        .objectStore('accounts')
        .add(account)
    );

    dispatch({
      type: 'ADD_ACCOUNT',
      payload: account,
    });
  };
}

export function editAccount(
  updatedAccount: Partial<Omit<PrivateAccountData, 'publicKey'>> &
    Pick<PrivateAccountData, 'publicKey'>
): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState, { useMainDb }) => {
    await useMainDb(async db => {
      const accountStore = db
        .transaction('accounts', 'readwrite', { durability: 'strict' })
        .objectStore('accounts');

      const account = await accountStore.get(updatedAccount.publicKey);

      invariant(account);

      Object.assign(account, updatedAccount);

      await accountStore.put(account);
    });

    dispatch({
      type: 'EDIT_ACCOUNT',
      payload: updatedAccount,
    });
  };
}

export function deleteAccount(
  publicKey: string
): AppThunkAction<Promise<void>> {
  return async (dispatch, getState, { useMainDb }) => {
    await useMainDb(db =>
      db
        .transaction('accounts', 'readwrite')
        .objectStore('accounts')
        .delete(publicKey)
    );

    const { accounts, network } = getState();
    const account = accounts?.find(acc => acc.publicKey === publicKey);

    invariant(account);

    const { walletType } = account;

    if (walletType === WalletType.KeeperMobile) {
      // only one mobile account is supported right now, so we need to disconnect
      // the last walletconnect session when deleting the account.
      const [{ Signer }, provider] = await Promise.all([
        import(
          /* webpackChunkName: "signer" */
          '@waves/signer'
        ),
        createWalletProvider(origin, walletType),
      ]);

      invariant(provider);

      const signer = new Signer({
        NODE_URL: WAVES_NETWORK_CONFIGS[network].nodeUrl,
      });
      signer.setProvider(provider);

      await signer.logout();
    }

    dispatch({
      type: 'DELETE_ACCOUNT',
      payload: { publicKey },
    });
  };
}

export function selectedAccountPublicKeyReducer(
  state: string | null = null,
  action: { type: 'SET_ACCOUNT'; payload: { publicKey: string | null } }
): string | null {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return action.payload.publicKey;
    default:
      return state;
  }
}

export function setSelectedAccount(
  publicKey: string | null
): AppThunkAction<Promise<void>> {
  return async (dispatch, _getState) => {
    const { accounts } = _getState();

    const selectedAccount = publicKey
      ? accounts?.find(account => account.publicKey === publicKey)
      : null;

    invariant(selectedAccount !== undefined);

    dispatch({
      type: 'SET_ACCOUNT',
      payload: { publicKey },
    });
  };
}
