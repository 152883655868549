import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  useDataServiceAssets,
  useDataServiceProtocols,
} from '../../cache/hooks';
import { PortfolioInvestments } from '../../investments/investments';
import { Container } from '../../layout/layout';
import { useAppSelector } from '../../store/react';
import { usePortfolioPageContext } from './portfolio';
import * as styles from './portfolio.module.css';

export function InvestmentsPage() {
  const { i18n } = useLingui();
  const { investments, usdPrices, investmentsWorth } =
    usePortfolioPageContext();

  const network = useAppSelector(state => state.network);
  const dataServiceAssets = useDataServiceAssets({ network });
  const dataServiceProtocols = useDataServiceProtocols({ network });

  return (
    <Container>
      {investments?.length === 0 ? (
        <h2 className={styles.emptyMessage}>{t(i18n)`No Investments yet`}</h2>
      ) : (
        <PortfolioInvestments
          dataServiceAssets={dataServiceAssets}
          dataServiceProtocols={dataServiceProtocols?.list}
          investments={investments}
          usdPrices={usdPrices}
          totalWorth={investmentsWorth}
        />
      )}
    </Container>
  );
}
