// extracted by mini-css-extract-plugin
export var btn = "qCHP1r5S58WGB3gsKhuq";
export var btn_loading = "m01SBOySiC8_J7QC0ofU";
export var btn_variant_danger = "SqmPGWiCQlmaMnpy809p";
export var btn_variant_default = "b6G2w5HI9kfyweOhKUNC";
export var btn_variant_icon = "yr_SYFWYaKlN7CjmPf6Y";
export var btn_variant_icon_danger = "v8b7mCt3IhifEOAMMDGm";
export var btn_variant_outlined = "SLj4EB8OuGWKaJY8YlBT";
export var btn_variant_outlined_danger = "dZSyZ7_u7WMNWHEJjWzP";
export var children = "L56W1UDs8bDlrv4X4KAI";
export var disabled = "EZYgjmQrIF0Rkfn24h7n";
export var spinner = "GdhZJMMAsYqGJUBB177j";
export var spinnerWrapper = "A8ISv8qJ1EbUgpXc7GHd";