interface ErrorLike {
  message: string;
}

export function isErrorLike(err: unknown): err is ErrorLike {
  return (
    typeof err === 'object' &&
    err !== null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (err as any).message === 'string'
  );
}

export function isAbortError(err: unknown) {
  return err instanceof DOMException && err.name === 'AbortError';
}

export function isKeeperInvalidNetworkError(err: unknown) {
  return (
    isErrorLike(err) &&
    /Invalid connect options. Signer connect (.*?) not equals keeper connect (.*?)/.test(
      err.message
    )
  );
}

export function isKeeperNotInstalledError(err: unknown) {
  return isErrorLike(err) && /WavesKeeper is not installed/.test(err.message);
}

export function isKeeperNoAccountsError(err: unknown) {
  return isErrorLike(err) && /Add Keeper Wallet account/.test(err.message);
}

export function isUserRejectionError(err: unknown) {
  if (!isErrorLike(err)) {
    return false;
  }

  // Keeper
  if (err.message === 'User denied message') {
    return true;
  }

  const match = err.message.match(
    /Details: Provider internal error: (.*). This is not error of signer./
  );

  return (
    match != null &&
    [
      'User denied message', // Keeper
      'User rejected.', // Keeper Mobile
    ].includes(match[1])
  );
}
