// extracted by mini-css-extract-plugin
export var blurredCircle = "ybjxjEWVjWus5qdhWH_g";
export var blurredCircleContainer = "Xj9pKN6_i1_scCygij5g";
export var debugAccountModal = "yQ9hEbe4rgUENDRWSTHp";
export var debugAccountModalContent = "RI5w5DmzuYtzg7BiDUJW";
export var getKeeperMessage = "uDUAbhjMuXhWDk6oRhjY";
export var heading = "bdFxKy6yh6uO4QJP54bm";
export var keeperWalletExtensionIllustration = "bD2Uu2eSKx7zb1pAJLxM";
export var keeperWalletMobileIllustration = "tegm3swsZUde3swCPyBE";
export var subheading = "vlGoph_6hgpK3TJHQn1h";
export var walletButton = "eAClJ7L5ajHt3Ygo6IXj";
export var walletButtonDescription = "Ac7m3cLHAbMk0UUYmyZZ";
export var walletButtonIllustration = "jAOWZCwO87eUUvpc0IG5";
export var walletButtonLabel = "ska7Ugaz7wUuVoCvZuNK";
export var walletButtonLogo = "ghCdSKPHjTrUNXDLQsDA";
export var walletButtonLogoContainer = "muFzbsAjbcmKeaKp_Y2g";
export var walletList = "d1p1hNfITRWYHQ8Q3DrZ";
export var walletListItem = "Mwpb731JjPWH5Akc5dPM";