import { Logo } from '../_core/logo';
import * as styles from './assetCell.module.css';

export function AssetCell({
  logo,
  ticker,
  id,
}: {
  logo?: string;
  ticker: string;
  id: string;
}) {
  return (
    <div className={styles.assetCell}>
      <Logo
        logo={logo}
        name={ticker}
        className={styles.assetCellLogo}
        objectId={id}
      />
      <span className={styles.assetCellTicker}>{ticker}</span>
    </div>
  );
}
