import {
  base58Decode,
  base58Encode,
  createAddress,
} from '@keeper-wallet/waves-crypto';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import invariant from 'tiny-invariant';

import {
  isErrorLike,
  isKeeperInvalidNetworkError,
  isKeeperNoAccountsError,
  isKeeperNotInstalledError,
  isUserRejectionError,
} from '../../_core/errors';
import { track } from '../../analytics';
import { useEntryContext } from '../../entry';
import { Container } from '../../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';
import { addAccount } from '../redux';
import { WalletType } from '../types';
import { createWalletProvider } from '../utils';
import * as styles from './addAccount.module.css';

function KeeperWalletMobileButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <li className={styles.walletListItem}>
      <button
        className={styles.walletButton}
        onClick={onClick}
        disabled={disabled}
      >
        <div className={styles.blurredCircleContainer}>
          <div className={styles.blurredCircle}></div>
        </div>
        <img
          className={clsx(
            styles.walletButtonIllustration,
            styles.keeperWalletMobileIllustration
          )}
          src={
            new URL('./keeperMobileIllustration.webp', import.meta.url).pathname
          }
          alt=""
        />

        <div className={styles.walletButtonLogoContainer}>
          <img
            className={styles.walletButtonLogo}
            src={new URL('./keeperMobileLogo.svg', import.meta.url).pathname}
            alt=""
          />
        </div>

        <h2 className={styles.walletButtonLabel}>
          <Trans>Add Keeper Mobile</Trans>
        </h2>
        <p className={styles.walletButtonDescription}>
          <Trans>Use your phone to connect account</Trans>
        </p>
      </button>
    </li>
  );
}

function KeeperWalletExtensionButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <li className={styles.walletListItem}>
      <button
        className={styles.walletButton}
        onClick={onClick}
        disabled={disabled}
      >
        <div className={styles.blurredCircleContainer}>
          <div className={styles.blurredCircle}></div>
        </div>
        <img
          className={clsx(
            styles.walletButtonIllustration,
            styles.keeperWalletExtensionIllustration
          )}
          src={
            new URL('./keeperExtensionIllustration.svg', import.meta.url)
              .pathname
          }
          alt=""
        />

        <div className={styles.walletButtonLogoContainer}>
          <img
            className={styles.walletButtonLogo}
            src={new URL('./keeperExtensionLogo.svg', import.meta.url).pathname}
            alt=""
          />
        </div>

        <h2 className={styles.walletButtonLabel}>
          <Trans>Add Keeper Extension</Trans>
        </h2>
        <p className={styles.walletButtonDescription}>
          {disabled ? (
            <Trans>Keeper Extension not installed</Trans>
          ) : (
            <Trans>Sign request with Keeper Extension</Trans>
          )}
        </p>
      </button>
    </li>
  );
}

export function AddAccount({ onSuccess }: { onSuccess?: () => void }) {
  const { origin } = useEntryContext();
  const { i18n } = useLingui();
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const isDebugMode = params.get('debug') != null;

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  const [isKeeperExtensionInstalled, setIsKeeperExtensionInstalled] =
    useState(false);

  useEffect(() => {
    import('@waves/provider-keeper').then(({ isKeeperInstalled }) => {
      isKeeperInstalled().then(setIsKeeperExtensionInstalled);
    });
  }, []);

  async function login(walletType: WalletType) {
    try {
      if (!(await navigator.storage.persisted())) {
        await navigator.storage.persist();
      }

      const [{ Signer }, provider] = await Promise.all([
        import(
          /* webpackChunkName: "signer" */
          '@waves/signer'
        ),
        createWalletProvider(origin, walletType),
      ]);

      invariant(provider);

      const signer = new Signer({
        NODE_URL: WAVES_NETWORK_CONFIGS[network].nodeUrl,
      });
      signer.setProvider(provider);

      const { publicKey } = await signer.login();

      const address = base58Encode(
        createAddress(
          base58Decode(publicKey),
          WAVES_NETWORK_CONFIGS[network].chainId
        )
      );

      await dispatch(
        addAccount({
          name: address,
          publicKey,
          walletType,
        })
      );

      track({ eventType: 'add account', walletType });

      onSuccess?.();
    } catch (err) {
      if (isUserRejectionError(err)) return;

      if (isKeeperInvalidNetworkError(err)) {
        // eslint-disable-next-line no-alert
        alert(
          t(i18n)`Please, switch network to ${
            network === Network.Mainnet ? 'Mainnet' : 'Testnet'
          } in Keeper Wallet Extension and try again`
        );
      } else if (
        err instanceof DOMException &&
        err.name === 'ConstraintError'
      ) {
        // eslint-disable-next-line no-alert
        alert(t(i18n)`Account already exists`);
      } else if (isKeeperNotInstalledError(err)) {
        // eslint-disable-next-line no-alert
        alert(t(i18n)`Keeper Wallet Extension is not installed`);
      } else if (isKeeperNoAccountsError(err)) {
        // eslint-disable-next-line no-alert
        alert(t(i18n)`You don't have any accounts in Keeper Wallet Extension`);
      } else {
        // eslint-disable-next-line no-console
        console.error(err);

        // eslint-disable-next-line no-alert
        alert(
          isErrorLike(err) ? err.message : t(i18n)`Unexpected error occurred`
        );
      }
    }
  }

  const addDebugAccount = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const { name, publicKey } = Object.fromEntries(formData);
    invariant(typeof name === 'string');
    invariant(typeof publicKey === 'string');

    await dispatch(
      addAccount({
        name,
        publicKey,
        walletType: WalletType.Debug,
      })
    );

    onSuccess?.();
  };

  const accounts = useAppSelector(state => state.accounts);

  if (!accounts) {
    return null;
  }

  if (accounts.length > 0) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <h1 className={styles.heading}>
        <Trans>Add Account</Trans>
      </h1>

      <p className={styles.subheading}>
        <Trans>
          You can connect an existing account through the Keeper mobile app or
          browser extension.
          <br />
          Choose a suitable option and accept the incoming request.
        </Trans>
      </p>

      <ul className={styles.walletList}>
        <KeeperWalletMobileButton
          onClick={() => {
            login(WalletType.KeeperMobile);
          }}
        />
        <KeeperWalletExtensionButton
          disabled={!isKeeperExtensionInstalled}
          onClick={() => {
            login(WalletType.KeeperExtension);
          }}
        />
      </ul>

      <div className={styles.getKeeperMessage}>
        <Trans>
          Don&apos;t have Keeper yet? Get it on our{' '}
          <a
            rel="noopener"
            href="https://keeper-wallet.app/#get-keeper"
            target="_blank"
          >
            website
          </a>
          .
        </Trans>
      </div>

      {isDebugMode && (
        <div
          onClick={event => {
            if (event.target === event.currentTarget) {
              params.delete('debug');
              navigate(`${location.pathname}?${params.toString()}`);
            }
          }}
          className={styles.debugAccountModal}
        >
          <div className={styles.debugAccountModalContent}>
            <form onSubmit={addDebugAccount}>
              <label>
                <span>Name:</span>
                <input name="name" type="text" required />
              </label>
              <label>
                <span>Public key:</span>
                <input name="publicKey" type="text" required />
              </label>

              <button type="submit">Add</button>
            </form>
          </div>
        </div>
      )}
    </Container>
  );
}
