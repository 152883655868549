import clsx from 'clsx';

import * as styles from './skeleton.module.css';

export function Skeleton({
  className,
  ...otherProps
}: React.ComponentProps<'div'>) {
  return <div {...otherProps} className={clsx(className, styles.root)} />;
}
