import { type I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import { type MetaDescriptor } from '../_core/meta';
import { AddAccount } from '../accounts/addAccount/addAccount';

export function createAddAccountMeta({ i18n }: { i18n: I18n }) {
  return (): MetaDescriptor => {
    const title = t(i18n)`Add account`;

    return {
      title,
      'og:title': title,
    };
  };
}

export function AddAccountPage() {
  const navigate = useNavigate();

  return <AddAccount onSuccess={() => navigate('/')} />;
}
