import { useEffect, useState } from 'react';

export function useMatchesMedia(query: string) {
  const [matches, setMatches] = useState<boolean>();

  useEffect(() => {
    const mql = window.matchMedia(query);
    setMatches(mql.matches);

    function handleChange(event: MediaQueryListEvent) {
      setMatches(event.matches);
    }

    mql.addEventListener('change', handleChange);

    return () => {
      mql.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
}
