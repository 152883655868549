import { Trans } from '@lingui/macro';
import React from 'react';
import { Form } from 'react-router-dom';

import { LinkButton } from '../_core/button';
import { ellipsis, encodeAddressFromPublicKey } from '../_core/utils';
import { AccountIcon } from '../icons/account';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import * as styles from './connectWalletButton.module.css';

export function ConnectWalletButton({ onClick }: { onClick?: () => void }) {
  const network = useAppSelector(state => state.network);
  const networkConfig = WAVES_NETWORK_CONFIGS[network];
  const accounts = useAppSelector(state => state.accounts);

  if (accounts == null || accounts.length === 0) {
    return (
      <LinkButton
        to="/add-account"
        variant="outlined"
        className={styles.connectWalletButton}
        onClick={onClick}
      >
        <Trans>Connect wallet</Trans>
      </LinkButton>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const account = accounts.at(0)!;

  const accountAddress = encodeAddressFromPublicKey(
    account.publicKey,
    networkConfig.chainId
  );

  return (
    <div className={styles.connectedAccountContainer}>
      <div className={styles.connectedAccount}>
        <AccountIcon className={styles.connectedAccountIcon} />
        <span>{ellipsis(accountAddress)}</span>
      </div>

      <Form action="/_actions/delete-account" method="post">
        <input type="hidden" name="publicKey" value={account.publicKey} />
        <input type="hidden" name="walletType" value={account.walletType} />

        <button type="submit" className={styles.logoutButton}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 7.75L12 7.75C12.4142 7.75 12.75 7.41421 12.75 7C12.75 6.58579 12.4142 6.25 12 6.25L8 6.25C6.48122 6.25 5.25 7.48122 5.25 9V21C5.25 22.5188 6.48122 23.75 8 23.75H12C12.4142 23.75 12.75 23.4142 12.75 23C12.75 22.5858 12.4142 22.25 12 22.25H8C7.30964 22.25 6.75 21.6904 6.75 21V9C6.75 8.30964 7.30964 7.75 8 7.75ZM18.4697 10.4697C18.1768 10.7626 18.1768 11.2374 18.4697 11.5303L21.1893 14.25L11 14.25C10.5858 14.25 10.25 14.5858 10.25 15C10.25 15.4142 10.5858 15.75 11 15.75L21.1893 15.75L18.4697 18.4697C18.1768 18.7626 18.1768 19.2374 18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303L23.5303 15.5303C23.8232 15.2374 23.8232 14.7626 23.5303 14.4697L19.5303 10.4697C19.2374 10.1768 18.7626 10.1768 18.4697 10.4697Z"
              fill="#1F5AF6"
            />
            <rect
              x="0.5"
              y="0.5"
              width="29"
              height="29"
              rx="7.5"
              stroke="#1F5AF6"
            />
          </svg>
        </button>
      </Form>
    </div>
  );
}
