import clsx from 'clsx';
import React from 'react';

import { Skeleton } from '../_core/skeleton';
import { Container } from '../layout/layout';
import * as styles from './pageHeader.module.css';

interface Props {
  className?: string;
  caption?: string;
  heading: React.ReactNode;
  children?: React.ReactNode;
}

export function PageHeader({ className, children, caption, heading }: Props) {
  return (
    <div className={clsx(className, styles.pageHeader)}>
      <Container>
        <span className={styles.pageHeaderTitle}>{caption}</span>

        {heading == null ? (
          <Skeleton className={styles.skeleton} />
        ) : (
          <h1 className={styles.pageHeaderValue}>{heading}</h1>
        )}

        {children}
      </Container>
    </div>
  );
}
