import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type BigNumber } from '@waves/bignumber';

import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Logo } from '../_core/logo';
import * as styles from './assetList.module.css';

const NO_DATA_PLACEHOLDER = '–';

interface AssetItem {
  id: string;
  ticker: string;
  logo?: string;
  price?: BigNumber;
  priceChange?: number;
  available: BigNumber;
  worth?: BigNumber;
}

export function AssetList({
  items,
  className,
}: {
  items: AssetItem[];
  className?: string;
}) {
  const { i18n } = useLingui();

  return (
    <div className={className}>
      <div className={styles.assetListHeader}>
        <span>{t(i18n)`Asset`}</span>
        <span>{t(i18n)`Total`}</span>
      </div>

      {items.map(({ id, ticker, logo, price, available, worth }) => (
        <div className={styles.assetListItem} key={id}>
          <div className={styles.assetListItemLogo}>
            <Logo logo={logo} name={ticker} objectId={id} />
          </div>

          <div className={styles.assetListItemContent}>
            <div className={styles.assetListItemContentTop}>
              <span>{ticker}</span>
              <span>{available.toFormat()}</span>
            </div>
            <div className={styles.assetListItemContentBottom}>
              <span>
                {price ? formatUsdPrice(price) : `$${NO_DATA_PLACEHOLDER}`}
              </span>
              <span className={styles.assetListItemWorth}>
                {worth ? formatUsdPrice(worth) : `$${NO_DATA_PLACEHOLDER}`}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
