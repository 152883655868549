import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { isErrorLike } from '../_core/errors';
import { FormHelperText } from '../_core/formHelperText';
import { Spinner } from '../_core/spinner';
import { encodeAddressFromPublicKey } from '../_core/utils';
import { type PrivateAccountData } from '../accounts/types';
import { createWalletProvider } from '../accounts/utils';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import * as styles from './swapAssets.module.css';

interface Props {
  accounts: PrivateAccountData[];
  senderPublicKey: string;
}

export function SwapAssets({ accounts, senderPublicKey }: Props) {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const networkConfig = WAVES_NETWORK_CONFIGS[network];

  const sender = accounts.find(
    account => account.publicKey === senderPublicKey
  );

  const [swapWidget, setSwapWidget] = useState<KeeperSwapWidget.SwapWidget>();
  const swapWidgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let widget: KeeperSwapWidget.SwapWidget | undefined;
    let cancelled = false;

    const script = document.createElement('script');
    script.src = 'https://swap-widget.keeper-wallet.app/lib/swap-widget.umd.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (cancelled) return;

      widget = KeeperSwapWidget.create(swapWidgetRef.current, {
        useInternalSigner: false,
      });
      setSwapWidget(widget);
    };

    return () => {
      cancelled = true;

      setSwapWidget(undefined);
      widget?.destroy();

      document.body.removeChild(script);
    };
  }, []);

  const [signerError, setSignerError] = useState('');

  useEffect(() => {
    if (!sender || !swapWidget) return;

    async function setAccount(
      account: PrivateAccountData,
      widget: KeeperSwapWidget.SwapWidget
    ) {
      try {
        const [{ Signer }, provider] = await Promise.all([
          import(
            /* webpackChunkName: "signer" */
            '@waves/signer'
          ),
          createWalletProvider(origin, account.walletType),
        ]);

        invariant(provider, 'provider');

        const signer = new Signer({
          NODE_URL: WAVES_NETWORK_CONFIGS[network].nodeUrl,
        });
        signer.setProvider(provider);

        widget.setWallet({
          address: encodeAddressFromPublicKey(
            account.publicKey,
            networkConfig.chainId
          ),
          signer,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        setSignerError(
          isErrorLike(err) ? err.message : t(i18n)`Unexpected error occurred`
        );
        return;
      }
    }

    setAccount(sender, swapWidget);
  }, [i18n, network, sender, swapWidget, networkConfig.chainId]);

  return (
    <div className={styles.root}>
      {signerError && (
        <div className={styles.senderCard}>
          <FormHelperText hasError>{signerError}</FormHelperText>
        </div>
      )}

      <div ref={swapWidgetRef} className={styles.widgetCard}>
        {!swapWidget && <Spinner size={32} />}
      </div>
    </div>
  );
}
