// extracted by mini-css-extract-plugin
export var accountHelperText = "RKFzZoumCKlcmTuXggNO";
export var accountRow = "Kl4PTWgTRHtK58eCxPC2";
export var assetAmountInTokens = "irulOGvVUQYBLrgDXBr0";
export var assetAmountInUsd = "HxvuHVMaxZO2BntjPGUC";
export var assetIcon = "QoJpBKwg5p9E34M6PSNx";
export var assetItem = "h_oAefYtXQutpUmJAMXw";
export var explorerUrl = "mzr0quFqxnB8j7xtphm_";
export var plate = "sWCQhX3S6faVE3Ansps1";
export var recipientLabel = "RCGX0LAxttg66Bgt8lf4";
export var recipientRow = "inWSPLZOskiRe9lcHijK";
export var root = "HX4RRtcMUlwMHY3U1j8C";
export var statusBox = "n3oaahJMBA9zAhUURN61";
export var statusCard = "KbZq7Iwy70TauRyaX0VJ";
export var statusErrorMessage = "eAXrrzaQps_DnvrFvYvB";
export var statusIcon = "YDSCLIlUYDkRBV9550rv";
export var statusMessage = "Zzn6zpP1ypXMncd8BssN";
export var statusRow = "sZCls86aS7e4kr2cW_Ah";
export var transactionInfo = "NEhrVwj_833tfxEpNQwJ";