import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';

import { track } from './analytics';
import { BrowserGlobal } from './browserGlobal';
import * as styles from './document.module.css';
import { useEntryContext } from './entry';

interface Props {
  children: React.ReactNode;
}

export function Document({ children }: Props) {
  const {
    css,
    dataServiceUrl,
    embedContext,
    initialReduxState,
    js,
    meta,
    nonce,
    sentryConfig,
    analyticsConfig,
  } = useEntryContext();

  const { i18n } = useLingui();

  const location = useLocation();

  useEffect(() => {
    track({ eventType: 'page view', pathname: location.pathname });
  }, [location.pathname]);

  return (
    <html
      className={clsx(styles.root, {
        [styles.root_insideIframe]: embedContext === 'iframe',
      })}
      lang={i18n.locale}
      translate="no"
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="var(--color-primary-500)" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />

        {meta &&
          Object.entries(meta).map(([name, value]) => {
            if (!value) {
              return null;
            }

            if (name === 'title') {
              return <title key="title">{String(value)}</title>;
            }

            return [value].flat().map(content => {
              if (/^(og|music|video|article|book|profile|fb):.+$/.test(name)) {
                return (
                  <meta
                    key={name + content}
                    property={name}
                    content={content as string}
                  />
                );
              }

              if (typeof content === 'string') {
                return (
                  <meta key={name + content} name={name} content={content} />
                );
              }

              return <meta key={name + JSON.stringify(content)} {...content} />;
            });
          })}

        {css.map(href => (
          <link key={href} rel="stylesheet" href={href} />
        ))}

        {js.map(src => (
          <script
            key={src}
            defer
            nonce={nonce}
            src={src}
            suppressHydrationWarning
          />
        ))}
      </head>

      <body>
        <div className={styles.children}>{children}</div>

        {typeof window !== 'undefined' && <ScrollRestoration />}

        <BrowserGlobal
          name="__ENTRY_CONTEXT__"
          nonce={nonce}
          value={{
            css,
            js,
            nonce,
            dataServiceUrl,
            sentryConfig,
            analyticsConfig,
          }}
        />

        <BrowserGlobal
          name="__INITIAL_REDUX_STATE__"
          nonce={nonce}
          value={initialReduxState}
        />
      </body>
    </html>
  );
}
