import { forwardRef } from 'react';

export function createSvgIcon(viewBox: string, element: React.ReactElement) {
  return forwardRef(function SvgIcon(
    props: Omit<
      React.ComponentProps<'svg'>,
      'fill' | 'height' | 'viewBox' | 'width'
    >,
    ref: React.ForwardedRef<SVGSVGElement>
  ) {
    return (
      <svg
        {...props}
        ref={ref}
        aria-hidden="true"
        fill="currentColor"
        height="1em"
        viewBox={viewBox}
        width="1em"
      >
        {element}
      </svg>
    );
  });
}
