import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Boundary } from '../_core/boundary';
import { Button } from '../_core/button';
import { ConnectWalletButton } from '../accounts/connectWalletButton';
import { useEntryContext } from '../entry';
import { BurgerIcon } from '../icons/burger';
import { CrossIcon } from '../icons/cross';
import * as styles from './header.module.css';

interface Props {
  className?: string;
  openMobileMenu: () => void;
}

export function Header({ className, openMobileMenu }: Props) {
  const { client, embedContext } = useEntryContext();

  return (
    <>
      <div className={clsx(styles.headerPlaceholder, className)} />

      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <Boundary className={styles.headerLayout}>
            <div className={styles.mobileMenuTriggerContainer}>
              <Button onClick={openMobileMenu} variant="icon">
                <BurgerIcon />
              </Button>
            </div>

            <div className={styles.logoContainer}>
              <Link className={styles.logo} to="/" title="Go to the home page">
                <img
                  src={new URL('./images/logo.svg', import.meta.url).pathname}
                  alt=""
                  width={37}
                  height={37}
                />
              </Link>

              <div className={styles.betaBadge}>Beta</div>
            </div>

            <div className={styles.rightControls}>
              {embedContext == null ? (
                <ConnectWalletButton />
              ) : (
                embedContext === 'iframe' &&
                client && (
                  <Button
                    variant="icon"
                    onClick={() => {
                      client.api.hide();
                    }}
                  >
                    <CrossIcon />
                  </Button>
                )
              )}
            </div>
          </Boundary>
        </div>
      </header>
    </>
  );
}
