import { Trans } from '@lingui/macro';

import { Spinner } from '../_core/spinner';
import { useAccounts } from '../accounts/requireAccounts';
import { Container } from '../layout/layout';
import { SwapAssets } from '../swap/swapAssets';
import * as styles from './swap.module.css';

export function SwapAssetsPage() {
  const accounts = useAccounts();
  const [selectedAccount] = useAccounts();

  const isLoading = accounts === null;

  return (
    <Container>
      <h1 className={styles.swapPageTitle}>
        <Trans>Swap Assets</Trans>
      </h1>
      {isLoading ? (
        <p className={styles.swapSpinnerWrapper}>
          <Spinner size={32} />
        </p>
      ) : (
        <SwapAssets
          accounts={accounts}
          senderPublicKey={selectedAccount.publicKey}
        />
      )}
    </Container>
  );
}
