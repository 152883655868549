import {
  base58Decode,
  base58Encode,
  createAddress,
} from '@keeper-wallet/waves-crypto';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import clsx from 'clsx';
import React, { createContext, useContext, useMemo } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { formatUsdPrice } from '../../_core/formatUsdPrice';
import { Money } from '../../_core/money';
import { isNotNull } from '../../_core/predicates';
import { useAccounts } from '../../accounts/requireAccounts';
import {
  useDataServiceLeasesInfo,
  useDataServiceProducts,
  useDataServiceUsdPrices,
  useWavesActiveLeases,
  useWavesAssetsDetails,
  useWavesBalances,
} from '../../cache/hooks';
import type {
  DataServiceProduct,
  DataServiceUsdPrices,
} from '../../dataService/redux';
import { PageHeader } from '../../investments/pageHeader';
import {
  calculateProductTotalWorth,
  type Investment,
  mapDataServiceProductToInvestment,
  mapWavesLeasingToInvestment,
  mergeWavesLeasesByRecipient,
} from '../../investments/utils';
import { Container } from '../../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { useAppSelector } from '../../store/react';
import type { AssetDetails } from '../../waves/redux';
import * as styles from './portfolio.module.css';

interface PortfolioPageContextType {
  assets: Partial<Record<string, AssetDetails>>;
  balances: Money[] | undefined;
  investments: Investment[] | undefined;
  usdPrices: DataServiceUsdPrices;
  walletWorth: BigNumber | undefined;
  investmentsWorth: BigNumber | undefined;
}

const PortfolioPageContext = createContext<PortfolioPageContextType>({
  assets: {},
  balances: undefined,
  investments: undefined,
  usdPrices: {},
  walletWorth: undefined,
  investmentsWorth: undefined,
});

export function usePortfolioPageContext() {
  return useContext(PortfolioPageContext);
}

export function PortfolioPage() {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const { chainId } = WAVES_NETWORK_CONFIGS[network];

  const selectedAccounts = useAccounts({ onlySelected: true });

  const addresses = useMemo(
    () =>
      selectedAccounts?.map(account =>
        base58Encode(createAddress(base58Decode(account.publicKey), chainId))
      ),
    [selectedAccounts, chainId]
  );

  const activeLeases = useWavesActiveLeases({
    network,
    addresses,
    only: 'outgoing',
  });
  const leasesInfo = useDataServiceLeasesInfo();

  const mergedLeases = useMemo(() => {
    const areLeasesLoading = addresses.some(
      address => activeLeases[address] == null
    );

    if (areLeasesLoading) {
      return;
    }

    const allActiveLeases = Object.values(activeLeases)
      .flat()
      .filter(isNotNull);

    return mergeWavesLeasesByRecipient(allActiveLeases);
  }, [activeLeases, addresses]);

  const wavesBalances = useWavesBalances({
    addresses,
    network,
  });

  const dataServiceProducts = useDataServiceProducts({
    addresses,
    network,
  });

  const assetIds = useMemo((): string[] => {
    if (!addresses) return [];

    const assetIdsSet = new Set<string>();

    for (const address of addresses) {
      const accountBalances = wavesBalances[address];
      const accountProducts = dataServiceProducts[address];

      if (accountBalances) {
        for (const balance of accountBalances.list) {
          if (balance) {
            assetIdsSet.add(balance.assetId);
          }
        }
      }

      if (accountProducts) {
        for (const product of accountProducts) {
          for (const amount of product.amounts) {
            assetIdsSet.add(amount.asset_id);
          }
        }
      }
    }

    return Array.from(assetIdsSet);
  }, [addresses, dataServiceProducts, wavesBalances]);

  const wavesAssetsDetails = useWavesAssetsDetails({
    assetIds,
    network,
  });

  const usdPrices = useDataServiceUsdPrices({
    assetIds,
    network,
  });

  const mergedProducts = useMemo((): DataServiceProduct[] | undefined => {
    if (!addresses) return;

    const result: Record<string, DataServiceProduct> = {};

    for (const address of addresses) {
      const accountProducts = dataServiceProducts[address];
      if (!accountProducts) return;

      for (const product of accountProducts) {
        const productAmounts: Money[] = [];

        for (const amount of product.amounts) {
          const assetDetails = wavesAssetsDetails[amount.asset_id];
          if (!assetDetails) return;

          productAmounts.push(Money.fromCoins(amount.coins, assetDetails));
        }

        if (result[product.product_id] == null) {
          result[product.product_id] = { ...product, amounts: productAmounts };
        } else {
          const allAmounts = [
            ...result[product.product_id].amounts,
            ...productAmounts,
          ];

          const totalAmounts = Object.values(
            allAmounts.reduce<Record<string, Money>>((amountsAcc, amount) => {
              const { assetId } = amount.assetInfo;

              if (amountsAcc[assetId] == null) {
                amountsAcc[assetId] = amount;
              } else {
                amountsAcc[assetId] = Money.fromTokens(
                  amountsAcc[assetId].getTokens().add(amount.getTokens()),
                  amount.assetInfo
                );
              }

              return amountsAcc;
            }, {})
          );

          result[product.product_id].amounts = totalAmounts;
        }
      }
    }

    return Object.values(result);
  }, [addresses, dataServiceProducts, wavesAssetsDetails]);

  const investments = useMemo(() => {
    if (!leasesInfo || !mergedProducts) {
      return;
    }

    return [
      ...mergedProducts.map(mapDataServiceProductToInvestment),
      ...(mergedLeases || []).map(leasing =>
        mapWavesLeasingToInvestment(leasing, leasesInfo)
      ),
    ];
  }, [mergedLeases, leasesInfo, mergedProducts]);

  const balances = useMemo(() => {
    if (!addresses) return;

    const mergedBalancesByAssetIds: Record<string, Money> = {};

    for (const address of addresses) {
      const accountBalance = wavesBalances[address];
      if (!accountBalance) return;

      for (const { assetId, balance } of accountBalance.list) {
        const asset = wavesAssetsDetails[assetId];
        if (!asset) return;

        const balanceMoney = Money.fromCoins(balance, asset);

        if (!mergedBalancesByAssetIds[assetId]) {
          mergedBalancesByAssetIds[assetId] = balanceMoney;
        } else {
          mergedBalancesByAssetIds[assetId] = Money.fromCoins(
            mergedBalancesByAssetIds[assetId]
              .getCoins()
              .add(balanceMoney.getCoins()),
            asset
          );
        }
      }
    }

    return Object.values(mergedBalancesByAssetIds);
  }, [addresses, wavesAssetsDetails, wavesBalances]);

  const walletWorth = useMemo(
    () =>
      balances?.reduce(
        (acc, balance) =>
          acc.add(
            balance.getTokens().mul(usdPrices[balance.assetInfo.assetId] ?? '0')
          ),
        new BigNumber(0)
      ),
    [balances, usdPrices]
  );

  const investmentsWorth =
    mergedLeases &&
    investments?.reduce(
      (acc, investment) =>
        acc.add(calculateProductTotalWorth(usdPrices, investment)),
      new BigNumber(0)
    );

  const contextValue = useMemo(
    (): PortfolioPageContextType => ({
      assets: wavesAssetsDetails,
      balances,
      investments,
      usdPrices,
      walletWorth,
      investmentsWorth,
    }),
    [
      wavesAssetsDetails,
      balances,
      investments,
      usdPrices,
      walletWorth,
      investmentsWorth,
    ]
  );

  const isLoading = investments == null || walletWorth == null;

  return (
    <>
      <PageHeader
        className={styles.header}
        caption={t(i18n)`Net worth`}
        heading={
          investmentsWorth == null || isLoading
            ? undefined
            : formatUsdPrice(walletWorth.add(investmentsWorth))
        }
      >
        <ul className={styles.menu}>
          <TabLink end label={t(i18n)`Wallet`} to="" />
          <TabLink label={t(i18n)`Investments`} to="investments" />
          <TabLink label={t(i18n)`NFTs`} to="nfts" />
        </ul>
      </PageHeader>

      <Container className={styles.content}>
        <PortfolioPageContext.Provider value={contextValue}>
          <Outlet />
        </PortfolioPageContext.Provider>
      </Container>
    </>
  );
}

interface TabLinkProps {
  end?: boolean;
  label: string;
  to: string;
}

function TabLink({ end, label, to }: TabLinkProps) {
  return (
    <li>
      <NavLink
        className={({ isActive, isPending }) =>
          clsx(styles.menuItem, {
            [styles.menuItem_active]: isActive,
            [styles.menuItem_pending]: isPending,
          })
        }
        end={end}
        to={to}
      >
        {label}
      </NavLink>
    </li>
  );
}
